import React from 'react'
import styled from 'styled-components'
import {Droppable} from "@hello-pangea/dnd"
import {AddFoodPlanItem} from "./AddFoodPlanItem"
import {getRecommendations} from "./Foodplan"
import {ProgressBar} from "react-bootstrap";
import {calculateStats} from "../../../helpers/FoodHelper";
import DraggableFoodItem from "./DraggableFoodItem";
import Form from 'react-bootstrap/Form';
import {useMutation} from "react-query";

const Container = styled.div`
    width: 220px;
    flex-grow: 1;

    &:nth-child(even) {
        background-color: #f8f9fc;
    }
`

const FoodList = styled.div`
    padding: 4px;
    max-height: 400px;
`

const Title = styled.h5`
    padding-top: 8px;
    color: #6c757d;
    text-align: center;
`

const Divider = styled.hr`
    margin: 4px;
`

const TimeSection = styled.div`
    padding-left: 4px;
`

export function FoodPlannerWeekday(props) {
    const plannedFoodSnacks = props.plannedFood["snacks"]
    const plannedFoodMorning = props.plannedFood["morning"]
    const plannedFoodNoon = props.plannedFood["noon"]
    const plannedFoodEvening = props.plannedFood["evening"]
    const date = props.date
    const recommendations = getRecommendations(props.ingredients, props.recipes)

    // const foodNotesMutation = useMutation({
    //     mutationFn: updateFoodNotes,
    //     onSuccess: () => {
    //         queryClient.invalidateQueries(["food", "notes", date.unix()])
    //     }
    // })

    const totalPlannedFood = [...plannedFoodSnacks, ...plannedFoodMorning, ...plannedFoodNoon, ...plannedFoodEvening]

    const lisaStats = calculateStats([totalPlannedFood.filter((e) => e["for_user"] === "lisa" || e["for_user"] === "both")], props.ingredients, props.recipes).totalStats
    const jakobStats = calculateStats([totalPlannedFood.filter((e) => e["for_user"] === "jakob" || e["for_user"] === "both")], props.ingredients, props.recipes).totalStats

    let kaloriesRedLisa = 0, kaloriesGreenLisa = 0, kaloriesRedJakob = 0, kaloriesGreenJakob = 0,
        proteinsRedLisa = 0, proteinsGreenLisa = 0, proteinsRedJakob = 0, proteinsGreenJakob = 0,
        labelKcalLisa = "", labelProteinsLisa = "", labelKaloriesJakob = "", labelProteinsJakob = "";

    const stats = {}
    for (let stat of props.stats) {
        stats[stat.plan_for] = stat.data
    }

    if (stats["lisa"] && stats["jakob"]) {
        if (lisaStats["energy-kcal"] > stats["lisa"]["energy-kcal"]) {
            kaloriesRedLisa = (lisaStats["energy-kcal"] - stats["lisa"]["energy-kcal"]) / stats["lisa"]["energy-kcal"] * 100
            kaloriesGreenLisa = 100 - kaloriesRedLisa
        } else {
            kaloriesRedLisa = 0
            kaloriesGreenLisa = lisaStats["energy-kcal"] / stats["lisa"]["energy-kcal"] * 100;
        }
        if (jakobStats["energy-kcal"] > stats["jakob"]["energy-kcal"]) {
            kaloriesRedJakob = (jakobStats["energy-kcal"] - stats["jakob"]["energy-kcal"]) / stats["jakob"]["energy-kcal"] * 100
            kaloriesGreenJakob = 100 - kaloriesRedJakob
        } else {
            kaloriesRedJakob = 0
            kaloriesGreenJakob = jakobStats["energy-kcal"] / stats["jakob"]["energy-kcal"] * 100;
        }
        if (lisaStats["proteins"] > stats["lisa"]["proteins"]) {
            proteinsRedLisa = (lisaStats["proteins"] - stats["lisa"]["proteins"]) / stats["lisa"]["proteins"] * 100
            proteinsGreenLisa = 100 - proteinsRedLisa
        } else {
            proteinsRedLisa = 0
            proteinsGreenLisa = lisaStats["proteins"] / stats["lisa"]["proteins"] * 100;
        }
        if (jakobStats["proteins"] > stats["jakob"]["proteins"]) {
            proteinsRedJakob = (jakobStats["proteins"] - stats["jakob"]["proteins"]) / stats["jakob"]["proteins"] * 100
            proteinsGreenJakob = 100 - proteinsRedJakob
        } else {
            proteinsRedJakob = 0
            proteinsGreenJakob = jakobStats["proteins"] / stats["jakob"]["proteins"] * 100;
        }

        labelKcalLisa = "L " + lisaStats["energy-kcal"].toFixed(0) + "/" + stats["lisa"]["energy-kcal"].toFixed(0) + " kcal";
        labelProteinsLisa = "L " + lisaStats["proteins"].toFixed(0) + "/" + stats["lisa"]["proteins"].toFixed(0) + "g proteins";
        labelKaloriesJakob = "J " + jakobStats["energy-kcal"].toFixed(0) + "/" + stats["jakob"]["energy-kcal"].toFixed(0) + " kcal";
        labelProteinsJakob = "J " + jakobStats["proteins"].toFixed(0) + "/" + stats["jakob"]["proteins"].toFixed(0) + "g proteins";
    }

    return <Container>
        <Title>{props.date.format("dd, DD.MM.")}</Title>

        <ProgressBar style={{margin: 4 + "px"}}>
            <ProgressBar variant="danger" now={kaloriesRedLisa} key={1}/>
            <ProgressBar style={{color: "black", textAlign: "left", paddingLeft: 5 + "px"}} variant="success"
                         now={kaloriesGreenLisa} key={2}
                         label={labelKcalLisa}/>
        </ProgressBar>
        <ProgressBar style={{margin: 4 + "px"}}>
            <ProgressBar variant="danger" now={proteinsRedLisa} key={1}/>
            <ProgressBar style={{color: "black", textAlign: "left", paddingLeft: 5 + "px"}} variant="info"
                         now={proteinsGreenLisa} key={2}
                         label={labelProteinsLisa}/>
        </ProgressBar>
        <ProgressBar style={{margin: 4 + "px"}}>
            <ProgressBar variant="danger" now={kaloriesRedJakob} key={1}/>
            <ProgressBar style={{color: "black", textAlign: "left", paddingLeft: 5 + "px"}} variant="success"
                         now={kaloriesGreenJakob} key={2}
                         label={labelKaloriesJakob}/>
        </ProgressBar>
        <ProgressBar style={{margin: 4 + "px"}}>
            <ProgressBar variant="danger" now={proteinsRedJakob} key={1}/>
            <ProgressBar style={{color: "black", textAlign: "left", paddingLeft: 5 + "px"}} variant="info"
                         now={proteinsGreenJakob} key={2}
                         label={labelProteinsJakob}/>
        </ProgressBar>
        {/*<TimeSection>Notes</TimeSection>*/}
        {/*<Divider/>*/}
        {/*<Form>*/}
        {/*    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">*/}
        {/*        <Form.Control as="textarea" rows={3} value={props.notes} onChange={(e) => foodNotesMutation.mutate(e.value)} />*/}
        {/*    </Form.Group>*/}
        {/*</Form>*/}
        <TimeSection>Snacks</TimeSection>
        <Divider/>
        <Droppable droppableId={props.index + "-snacks"}>
            {provided => (<FoodList ref={provided.innerRef} {...provided.droppableProps}>
                {plannedFoodSnacks.map((foodItem, index) => <DraggableFoodItem key={index} food={foodItem}
                                                                               index={index}
                                                                               uniquename={props.index + "not-" + index}/>)}
                {provided.placeholder}
                <AddFoodPlanItem recommendations={recommendations} ingredients={props.ingredients}
                                 recipes={props.recipes}
                                 date={date}
                                 partOfDay={"snacks"}
                />
            </FoodList>)}
        </Droppable>

        <TimeSection>Morning</TimeSection>
        <Divider/>
        <Droppable droppableId={props.index + "-morning"}>
            {provided => (<FoodList ref={provided.innerRef} {...provided.droppableProps}>
                {plannedFoodMorning.map((foodItem, index) => <DraggableFoodItem key={index} food={foodItem}
                                                                                index={index}
                                                                                uniquename={props.index + "mor-" + index}/>)}
                {provided.placeholder}
                <AddFoodPlanItem recommendations={recommendations} ingredients={props.ingredients}
                                 recipes={props.recipes}
                                 date={date}
                                 partOfDay={"morning"}
                />
            </FoodList>)}
        </Droppable>

        <TimeSection>Noon</TimeSection>
        <Divider/>
        <Droppable droppableId={props.index + "-noon"}>
            {provided => (<FoodList ref={provided.innerRef} {...provided.droppableProps}>
                {plannedFoodNoon.map((foodItem, index) => <DraggableFoodItem key={index} food={foodItem}
                                                                             index={index}
                                                                             uniquename={props.index + "noo-" + index}/>)}
                {provided.placeholder}
                <AddFoodPlanItem recommendations={recommendations} ingredients={props.ingredients}
                                 recipes={props.recipes}
                                 date={date}
                                 partOfDay={"noon"}
                />
            </FoodList>)}
        </Droppable>

        <TimeSection>Evening</TimeSection>
        <Divider/>
        <Droppable droppableId={props.index + "-evening"}>
            {provided => (<FoodList ref={provided.innerRef} {...provided.droppableProps}>
                {plannedFoodEvening.map((foodItem, index) => <DraggableFoodItem key={index} food={foodItem}
                                                                                index={index}
                                                                                uniquename={props.index + "eve-" + index}/>)}
                {provided.placeholder}
                <AddFoodPlanItem recommendations={recommendations} ingredients={props.ingredients}
                                 recipes={props.recipes}
                                 date={date}
                                 partOfDay={"evening"}
                />
            </FoodList>)}
        </Droppable>

    </Container>;
}