import React from 'react';
import UpdatableCard from "../UpdatableCard";
import styled from "styled-components";
import {calculateStats} from "../../../helpers/FoodHelper";

const ErrorBox = styled.span`
  color: red;
`

function FoodStatsCard(props) {
    if (!props.stats) {
        return <UpdatableCard title={"Loading..."} empty={true}/>
    }

    let {totalStats, errors} = calculateStats(props.plans, props.ingredients, props.recipes);

    return (<UpdatableCard title={props.for + " Stats"} empty={false}>
        {["energy-kcal", "carbohydrates", "fat", "proteins"].map((key, index) => <p
            key={index}>{key + ": " + totalStats[key].toFixed(2) + "/" + props.stats[key]}</p>)}
        <ErrorBox>
            {errors ? <p>{errors}</p> : null}
        </ErrorBox>
    </UpdatableCard>);
}

export default FoodStatsCard;