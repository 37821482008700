import React, {useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import {deleteEntity} from "../api/entityApiHandler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {formatStats} from "../helpers/helpers";
import ReactTooltip from "react-tooltip";
import {btnoptions} from "./ui/ButtonList";
import {useMutation, useQueryClient} from "react-query";

export const ResultBox = styled.div`
    ${({highlighted}) => highlighted ? "background: lightgray" : "background: white"};
    width: 100%;
    height: 200px;
    padding: 8px;
    border: lightgray 1px solid;
    overflow: auto;
`

export function IngredientBox(props) {

    const tooltip = useRef()
    const queryClient = useQueryClient()

    const deleteEntityMutation = useMutation({
        mutationFn: deleteEntity,
        onSuccess: () => {
            queryClient.invalidateQueries('recipes')
            queryClient.invalidateQueries('ingredients')
        }
    })

    const errorWrap = (tooltip, toCall) => {
        try {
            toCall();
        } catch (e) {
            tooltip.setAttribute("data-tip", e.toString())
            ReactTooltip.show(tooltip)
        }
    }

    const createButtons = (item, options) => {
        let buttons = []

        options.forEach(button => {
            let buttonstyle = {};
            switch (button) {
                case btnoptions.DELETE:
                    buttons.push(<button title="Delete"
                                         onClick={() => errorWrap(tooltip.current, () => deleteEntityMutation.mutate(item))}
                                         className="btn btn-danger btn-circle btn-sm" style={buttonstyle}>
                        <i className="fas fa-trash"/>
                        <p ref={ref => tooltip.current = ref} data-tip={"Unknown Error"}></p>
                    </button>)
                    break
                case btnoptions.EDIT:
                    buttons.push(<button title="Edit"
                                         onClick={() => errorWrap(tooltip.current, () => {
                                             if (props.onEdit) {
                                                 props.onEdit(item)
                                             } else {
                                                 throw new Error("No onEdit function provided!")
                                             }
                                         })}
                                         className="btn btn-info btn-circle btn-sm" style={{marginLeft: ".5em"}}>
                        <FontAwesomeIcon icon={solid('wrench')}/>
                        <p ref={ref => tooltip.current = ref} data-tip={"Unknown Error"}></p>
                    </button>)
                    break
                default:
                    alert("Unknown button: " + button)
                    break
            }
        });

        return buttons.map((button, index) => <span key={index}>
                        {button}
                    </span>);
    }

    if (!props.item.stats) {
        return <ResultBox>No stats</ResultBox>
    }

    const formattedStats = formatStats(props.item.stats)

    let title_width = 4
    let nutrient_width = 4
    return <ResultBox highlighted={props.highlighted}>
        {!props.noimg ? <div style={{
            width: 20 + '%', height: 100 + '%', float: "left"
        }}>
            <img style={{maxWidth: 100 + "%", maxHeight: 100 + "%"}} src={props.item.img_url}
                 alt={props.item.name}/>
        </div> : ""}
        <Container style={{width: 75 + '%', float: "left"}}>
            <Row>
                <Col>
                    <b>{props.item.name}</b>
                </Col>
            </Row>
            <Row>
                <Col xs={title_width}>
                    Nutrient:
                </Col>
                <Col xs={nutrient_width}>
                    / 100g
                </Col>
                <Col xs={nutrient_width}>
                    / serving
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col xs={title_width}>
                    Energy:
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["energy_100g"]}
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["energy_serving"]}
                </Col>
            </Row>
            <Row>
                <Col xs={title_width}>
                    Carbs:
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["carbohydrates_100g"]}
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["carbohydrates_serving"]}
                </Col>
            </Row>
            <Row>
                <Col xs={title_width}>
                    Fat:
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["fat_100g"]}
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["fat_serving"]}
                </Col>
            </Row>
            <Row>
                <Col xs={title_width}>
                    Proteins:
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["proteins_100g"]}
                </Col>
                <Col xs={nutrient_width}>
                    {formattedStats["proteins_serving"]}
                </Col>
            </Row>
        </Container>
        {props.options ? <div style={{
            float: "left",
            width: 5 + '%'
        }}>{createButtons(props.item, props.options)}</div> : ""}
    </ResultBox>

}

IngredientBox.propTypes = {
    highlighted: PropTypes.bool,
    item: PropTypes.object,
    kcal: PropTypes.number,
    carbs: PropTypes.number,
    fat: PropTypes.number,
    proteins: PropTypes.number,
    options: PropTypes.array,
    noimg: PropTypes.bool,
    onEdit: PropTypes.func
};