import React from 'react';
import UpdatableCard from "./UpdatableCard";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import {InputGroup} from "react-bootstrap";
import {btnoptions, ButtonList} from "../ui/ButtonList";
import {formatStats} from "../../helpers/helpers";
import BootstrapTable from 'react-bootstrap-table-next';

function RecipesCard({title, recipes, showModal, day}) {
    const [text, setText] = React.useState("");
    const [show_100g, setShow100g] = React.useState(false);

    if (!recipes.isSuccess) {
        return <div>Loading...</div>
    }

    let list = Object.values(recipes.data);

    list = list.filter((recipe) => recipe.name.toLowerCase().includes(text.toLowerCase()))
    if (list.length > 50) {
        list = list.slice(0, 50)
        title += " (showing first 50)"
    }
    let recipesdata = list.map((value, index) => {
        const formattedStats = formatStats(value.stats)
        const suffix = show_100g ? "_100g" : "_serving"
        const buttonList = <ButtonList item={value}
                                       options={[btnoptions.EDIT, btnoptions.DELETE]}
                                       onEdit={() => showModal(value)}/>

        return {
            "name": value["name"],
            "energy": {"data": formattedStats["energy" + suffix], "sortValue": value.stats["energy-kcal"]},
            "carbs": {"data": formattedStats["carbohydrates" + suffix], "sortValue": value.stats["carbohydrates"]},
            "fat": {"data": formattedStats["fat" + suffix], "sortValue": value.stats["fat"]},
            "protein": {"data": formattedStats["proteins" + suffix], "sortValue": value.stats["proteins"]},
            "score": {"data": formattedStats["score"], "sortValue": value.stats["proteins"] * 1000 / value.stats["energy-kcal"]},
            "actions": buttonList
        }
    });

    const sortCaret = (order, column) => {
        if (!order) return (<span><i className="fas fa-caret-up"></i>
        <i className="fas fa-caret-down"></i></span>);
        else if (order === 'asc') return (<i className="fas fa-caret-up"></i>);
        else if (order === 'desc') return (<i className="fas fa-caret-down"></i>);
        return null;
    }

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true,
        sortCaret: sortCaret,
    }, {
        dataField: 'energy',
        text: 'Energy',
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell, row) => row["energy"]["data"],
        sortValue: (cell, row) => row["energy"]["sortValue"]
    }, {
        dataField: 'carbs',
        text: 'Carbs',
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell, row) => row["carbs"]["data"],
        sortValue: (cell, row) => row["carbs"]["sortValue"]
    }, {
        dataField: 'fat',
        text: 'Fat',
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell, row) => row["fat"]["data"],
        sortValue: (cell, row) => row["fat"]["sortValue"]
    }, {
        dataField: 'protein',
        text: 'Protein',
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell, row) => row["protein"]["data"],
        sortValue: (cell, row) => row["protein"]["sortValue"]
    }, {
        dataField: 'score',
        text: 'Score',
        sort: true,
        sortCaret: sortCaret,
        formatter: (cell, row) => row["score"]["data"],
        sortValue: (cell, row) => row["score"]["sortValue"]
    }, {
        dataField: 'actions',
        text: 'Actions'
    }
    ];

    return (<UpdatableCard title={title} empty={false}>
        <Form.Check
            type="switch"
            label="Show per 100g instead of serving"
            id="custom-switch"
            onChange={(e) => setShow100g(e.target.checked)}
            checked={show_100g}
        />
        <InputGroup style={{marginBottom: 5 + "px"}}>
            <Form.Control placeholder={"Search recipes..."}
                          onChange={(event) => setText(event.target.value)}/>
            <Button onClick={(e) => {
                e.preventDefault();
                showModal()
            }}>
                Add new recipe
            </Button>
        </InputGroup>
        <BootstrapTable striped bordered hover columns={columns} data={recipesdata} keyField={"name"}/>
    </UpdatableCard>);
}

export default RecipesCard