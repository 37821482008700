import {authHeader} from "./authHeader";
import {handleResponse} from "./handleResponse";

export function deleteEntity(item) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/ent/' + item._id["$oid"], requestOptions)
        .then(handleResponse);
}

export function updateItem(item) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(item),
    };

    return fetch(process.env.REACT_APP_API_URL + '/ent/' + item._id["$oid"], requestOptions)
        .then(handleResponse);
}

export function updateGoal(goal) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(goal),
    };

    return fetch(process.env.REACT_APP_API_URL + '/projects/' + goal._id["$oid"], requestOptions)
        .then(handleResponse);
}