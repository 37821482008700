import styled from "styled-components";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {submitFoodPlanItem} from "../../../api/foodApiHandler";
import {parseFoodPlanItem} from "../../../parser/FoodplanItemParser";
import Omnibar from "../../Omnibar";
import {useMutation, useQueryClient} from "react-query";

const AddNewFoodPlanItem = styled.div`
    border: lightgray 2px dashed;
    border-radius: 4px;
    color: lightgray;
    display: inline-flex;
    width: calc(100% - 4px);
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
    margin: 2px;
`

export const AddButton = styled.div`
    &:hover {
        color: gray;
        cursor: pointer;
    }
`

export function AddFoodPlanItem(props) {
    const [editing, setEditing] = React.useState(false);
    const queryClient = useQueryClient()
    const submitFoodPlanMutation = useMutation({
            mutationFn: ({entry, item, _for}) => {
                return submitFoodPlanItem(_for, parseFoodPlanItem(item, entry.split(" "), props.ingredients, props.recipes), props.date, props.partOfDay);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['food', 'planned_food'])
            }
        }
    )

    if (editing) {
        return <Omnibar autofocus
                        onSubmit={(entry, item) => {
                            submitFoodPlanMutation.mutate({entry: entry, item: item, _for: editing})
                            setEditing(false)
                        }}
                        placeholder={"Plan food for " + editing} resizeable={true}
                        recommendations={props.recommendations}
                        smallRecommendations={true}
                        hidebutton={true} onEscape={() => setEditing(false)}
        />
    }

    return <AddNewFoodPlanItem>
        <AddButton title={"Add for Lisa"} onClick={() => setEditing("lisa")}>
            <FontAwesomeIcon icon={solid('plus')}/>
        </AddButton>
        <AddButton title={"Add for both"} onClick={() => setEditing("both")}>
            <FontAwesomeIcon icon={solid('plus')}/>
        </AddButton>
        <AddButton title={"Add for Jakob"} onClick={() => setEditing("jakob")}>
            <FontAwesomeIcon icon={solid('plus')}/>
        </AddButton>
    </AddNewFoodPlanItem>;
}