import React, {useMemo, useRef, useState} from 'react';
import ReactTooltip from "react-tooltip";
import Autocomplete from "react-autocomplete";
import PropTypes from "prop-types";
import {IngredientBox} from "./IngredientBox";
import {SmallIngredientBox} from "./SmallIngredientBox";

function Omnibar(props) {
    const [text, setText] = useState("")
    const [item, setItem] = useState(null)
    const [cachedResults, setCachedResults] = useState([])
    const [selected, setSelected] = useState(false)
    const tooltip = useRef(null)
    const bar = useRef(null)
    const recommendations = useMemo(() => {
        const searchtext = text.trim().toLowerCase()
        const recommendations = []

        for (let res of cachedResults) {
            recommendations.push(res)
            if (recommendations.length >= 20) {
                break
            }
        }

        if (props.recommendations) {
            for (let rec of props.recommendations) {
                if (rec.name.toLowerCase().includes(searchtext)) {
                    recommendations.push(rec)
                }
                if (recommendations.length >= 20) {
                    break
                }
            }
        }
        return recommendations
    }, [text, cachedResults, props.recommendations])

    const handleKey = event => {
        ReactTooltip.hide(tooltip.current)
        if (event.key === 'Enter' && text !== "") {
            submit()
        } else if (event.key === 'Backspace') {
            setSelected(false)
        } else if (event.keyCode === 27 && props.onEscape) {
            props.onEscape()
        }
    }

    const submit = () => {
        try {
            props.onSubmit(text, item)
            setText("")
            setItem(null)
            setCachedResults([])
        } catch (e) {
            tooltip.current.setAttribute("data-tip", e.toString())
            ReactTooltip.show(tooltip.current)
        }
    }

    return (<div
        className={props.resizeable ? "form-inline mr-auto w-100 navbar-search" : "d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"}
    >
        <ReactTooltip type={"error"}/>
        <p ref={ref => tooltip.current = ref} data-tip={"Unknown Error"}></p>
        <div className="input-group" style={{width: 100 + '%', marginTop: 5 + 'px'}}>
            <Autocomplete
                ref={(input) => {
                    bar.current = input;
                }}
                style={{width: 100 + '%'}}
                getItemValue={(item) => {
                    if (item.name) {
                        return item.name;
                    } else if (item.product_name) {
                        return item.product_name;
                    } else {
                        alert("Error, please check the logs.")
                        return "Error, please check the logs."
                    }
                }}
                items={recommendations}
                wrapperProps={{
                    className: "bg-light", style: {
                        position: "relative",
                        flex: "1 1 auto",
                        width: 1 + "%",
                        minWidth: 0,
                        marginBottom: 5 + 'px',
                        marginTop: 5 + 'px',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    }
                }}
                isItemSelectable={function () {
                    return true
                }}
                menuStyle={{
                    fontSize: '90%', position: 'absolute', zIndex: 100, maxHeight: '50%', top: 40, left: 0
                }}
                renderItem={(item, isHighlighted) => <div
                    key={(item["_id"] && item["_id"]["$oid"]) ? item["_id"]["$oid"] : item["db_id"]}>
                    {!props.smallRecommendations ?
                        <IngredientBox highlighted={isHighlighted}
                                       onEdit={() => {
                                           props.showModal()
                                       }
                                       }
                                       item={item}/> : <SmallIngredientBox highlighted={isHighlighted}
                                                                           item={item}/>
                    }
                </div>}
                renderInput={props => <input autoFocus={props.autofocus} placeholder={props.placeholder}
                                             style={{width: 100 + '%', ...props.inputstyle}}
                                             className="form-control bg-light border-0" {...props}
                                             onSubmit={(event) => {
                                                 event.preventDefault()
                                             }}
                                             onKeyDown={(event) => {
                                                 handleKey(event);
                                                 props.onKeyDown(event);
                                             }}/>}
                value={text}
                onChange={(e) => {
                    setText(e.target.value)
                }}
                onSelect={(e, item) => {
                    setSelected(true)
                    setText(e)
                    setItem(item)
                    if (bar.current) {
                        bar.current.focus()
                    }
                    if (props.onSelect) {
                        props.onSelect(item)
                    }
                }}
            />
            {!props.hidebutton ? <div className="input-group-append">
                <button className="btn btn-primary" onClick={(event) => {
                    event.preventDefault()
                    if (text === "") {
                        if (props.onEmptySubmit) {
                            props.onEmptySubmit()
                        }
                    } else {
                        submit();
                    }
                }}>
                    <i className="fas fa-plus fa-sm"/>
                </button>
            </div> : ""}
        </div>
    </div>);
}

Omnibar.propTypes = {
    placeholder: PropTypes.string,
    recommendations: PropTypes.array,
    onSubmit: PropTypes.func,
    onEmptySubmit: PropTypes.func
};

export default Omnibar;