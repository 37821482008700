import React from 'react';
import styled from 'styled-components'
import {Droppable} from "@hello-pangea/dnd";
import DraggableTodo from "./DraggableTodo";

const Container = styled.div`
  width: 220px;
  flex-grow: 1;
  &:nth-child(even) {
    background-color: #f8f9fc;
  }
`

const TaskList = styled.div`
  padding-top: 8px;
  max-height: 400px;
  min-height: 100px;
  overflow-y: scroll;
  scrollbar-color: gray white;
`

const Title = styled.h6`
  padding-top: 8px;
  color: #6c757d;
  text-align: center;
`

export function DroppableWeekday(props) {
        let bountytotal = 0
        for (let todo of props.todos) {
            if (todo.bounty && todo.bounty !== 0) {
                bountytotal += todo.bounty
            }
        }

        return <Droppable droppableId={props.index}>
            {provided => (
                <Container ref={provided.innerRef} {...provided.droppableProps}>
                    <Title>{props.title}</Title>
                    <TaskList>
                        {props.todos.map((todo, index) => <DraggableTodo key={"weekday-" + props.index + "-todo-" + index} todo={todo} index={index}
                                                                              uniquename={"weekday-" + props.index + "-todo-" + index}/>)}
                        {provided.placeholder}
                    </TaskList>
                </Container>
            )}
        </Droppable>
}