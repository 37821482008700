import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';

import {authenticationService} from "../../services/authentication.service";
import {Navigate} from "react-router";
import {useLocation, useNavigate, useParams,} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{location, navigate, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}

function Login(props) {
    if (authenticationService.currentUserValue) {
        return <Navigate to="/app/dashboard"/>
    }
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"/>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                        </div>

                                        <Formik
                                            initialValues={{
                                                username: '',
                                                password: ''
                                            }}
                                            validationSchema={Yup.object().shape({
                                                username: Yup.string().required('Username is required'),
                                                password: Yup.string().required('Password is required')
                                            })}
                                            onSubmit={({username, password}, {setStatus, setSubmitting}) => {
                                                setStatus();
                                                authenticationService.login(username, password)
                                                    .then(
                                                        _ => {
                                                            props.router.navigate('/');
                                                        },
                                                        error => {
                                                            setSubmitting(false);
                                                            setStatus(error.toString());
                                                        }
                                                    );
                                            }}
                                        >
                                            {({errors, status, touched, isSubmitting}) => (
                                                <Form className="user">
                                                    <div className="form-group">
                                                        <label htmlFor="username">Username</label>
                                                        <Field name="username" type="text"
                                                               className={'form-control form-control-user' + (errors.username && touched.username ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="username" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password">Password</label>
                                                        <Field name="password" type="password"
                                                               className={'form-control form-control-user' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="password" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit"
                                                                className="btn btn-primary btn-user btn-block"
                                                                disabled={isSubmitting}>Login
                                                        </button>
                                                        {isSubmitting &&
                                                            <img
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                                                alt=""/>
                                                        }
                                                    </div>
                                                    {status &&
                                                        <div className={'alert alert-danger'}>{status}</div>
                                                    }
                                                </Form>
                                            )}
                                        </Formik>
                                        <hr/>
                                        {/*<div className="text-center">*/}
                                        {/*    <a className="small" href=".">Forgot*/}
                                        {/*        Password?</a>*/}
                                        {/*</div>*/}
                                        {/*<div className="text-center">*/}
                                        {/*    <a className="small" href=".">Create an Account!</a>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default withRouter(Login);