import React from 'react';
import TodoEntry from "../TodoEntry";
import UpdatableCard from "./UpdatableCard";
import {Droppable} from "@hello-pangea/dnd";
import DraggableTodo from "./DraggableTodo";
import styled from "styled-components";
import {getTodoText} from "../../helpers/helpers";

const TaskList = styled.div`
    max-height: 400px;
    overflow-y: scroll;
    scrollbar-color: gray white;
`

function TaskListCard(props) {
    let tasks = "";
    if (props.tasks) {
        if (props.draggable) {
            tasks = <Droppable droppableId={props.id}>
                {provided => (
                    <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                        {props.tasks.map((todo, index) =>
                            <DraggableTodo key={index} todo={todo} index={index}
                                           uniquename={props.id + "-" + index}/>)}
                        {provided.placeholder}
                    </TaskList>
                )}
            </Droppable>
        } else {
            tasks = props.tasks.map((item, index) =>
                <TodoEntry showTodoModal={props.showTodoModal} item={item} key={index}
                           options={props.options}
                           currentDate={props.currentDate}
                           getText={getTodoText}/>
            );
        }
    }

    return (
        <UpdatableCard title={props.title} titleLink={props.link}
                       empty={!props.tasks || props.tasks.length === 0}>
            <ul style={{
                listStyle: "none",
                paddingInline: 0
            }}>
                {tasks}
            </ul>
        </UpdatableCard>
    );
}

export default TaskListCard;