import React from 'react';
import {Navigate} from 'react-router-dom';

import {authenticationService} from '../services/authentication.service';

function PrivateRoute(props) {
    if (!authenticationService.currentUserValue) {
        return <Navigate to={{pathname: '/login', state: {from: props.location}}}/>
    }

    return (props.children);
}

export default PrivateRoute;