import {getMorningTime, mongoToMoment, MORNING_TIME} from "./dateHelper";
import moment from "moment";
import ReactTooltip from "react-tooltip";

export function errorWrap(tooltip, toCall) {
    try {
        toCall();
    } catch (e) {
        tooltip.setAttribute("data-tip", e.toString())
        ReactTooltip.show(tooltip)
    }
}

function hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

const colors = ['#777777', '#888888', '#999999', '#AAAAAA', '#9F7070', '#9F8F70', '#9F9F70', '#8F9F70', '#709F70', '#709F8F', '#709F9F', '#708F9F', '#70709F', '#8F709F', '#9F709F', '#9F708F', '#BF7070', '#BF8F70', '#BFBF70', '#8FBF70', '#70BF70', '#70BF8F', '#70BFBF', '#708FBF', '#7070BF', '#8F70BF', '#BF70BF', '#BF708F', '#BF6060', '#BF8F60', '#BFBF60', '#8FBF60', '#60BF60', '#60BF8F', '#60BFBF', '#608FBF', '#6060BF', '#8F60BF', '#BF60BF', '#BF608F', '#BF5050', '#BF8F50', '#BFBF50', '#8FBF50', '#50BF50', '#50BF8F', '#50BFBF', '#508FBF', '#5050BF', '#8F50BF', '#BF50BF', '#BF508F',]

const priority_colors = ['#AAAAAA', // ???
    '#40ABAB', // ??
    '#40AB40', // ?
    null,      // default
    '#ECEC66', // !
    '#FF7700', // !!
    '#FF0000', // !!!
]


export function stringToColor(str) {
    return colors[(hashCode(str) % colors.length + colors.length) % colors.length]
}

export function priorityToColor(prio) {
    return priority_colors[prio + 3]
}

export function durationToString(secs) {
    var sec_num = parseInt(secs, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);

    let time = ""

    if (hours !== 0) {
        time += hours + 'h'
    }

    if (minutes !== 0) {
        time += minutes + 'm'
    }

    return time
}

export function sortTodos(a, b) {
    if (a.done === b.done) {
        let startTimeA, startTimeB
        if ("start_time" in a) {
            startTimeA = mongoToMoment(a.start_time)
        } else if ("planned_after" in a) {
            startTimeA = mongoToMoment(a.planned_after)
        } else {
            startTimeA = 90000000000000
        }

        if ("start_time" in b) {
            startTimeB = mongoToMoment(b.start_time)
        } else if ("planned_after" in b) {
            startTimeB = mongoToMoment(b.planned_after)
        } else {
            startTimeB = 90000000000000
        }

        if (startTimeA > startTimeB) {
            return 1
        } else if (startTimeB > startTimeA) {
            return -1
        } else {
            return ("priority" in b ? b.priority : 0) - ("priority" in a ? a.priority : 0)
        }
    } else if (a.done) {
        return 1
    } else if (b.done) {
        return -1
    }

    return 0
}

export function getTodoText(item) {
    let after

    if (item["done"] && "start_time" in item && item["start_time"] != null) {
        let time = moment.utc(item.start_time["$date"]).local()
        if (!("duration" in item) || item.duration === 0) {
            after = <small
                className="text-muted">{time.format("HH:mm")}</small>
        } else {
            let end_time = time.clone().add(item.duration, "seconds")
            after = <small
                className="text-muted">{time.format("HH:mm") + " - " + end_time.format("HH:mm") + ", " + durationToString(item.duration)}</small>
        }
    } else if ("start_time" in item && item["start_time"] != null) {
        let time = moment.utc(item.start_time["$date"]).local()
        let morning = moment.utc().set(MORNING_TIME).local()
        if (time <= morning) {
            after = <small>{"▶ " + time.format("HH:mm on D.M.YY")}</small>
        } else if (time <= moment()) {
            after = <small>{"▶ " + time.format("HH:mm")}</small>
        } else {
            after = <small>{"▶ " + time.format("HH:mm")}</small>
        }
    } else if ("planned_after" in item && item["planned_after"] != null && mongoToMoment(item.planned_after) < getMorningTime()) {
        after = <small style={{color: "red"}}>{"overdue!"}</small>
    } else if ("planned_after" in item && item["planned_after"] != null) {
        let time = mongoToMoment(item.planned_after)
        if ("duration" in item) {
            after = <small className="text-muted">{time.format("HH:mm") + " - " + time.clone().add(item.duration, "seconds").format("HH:mm")}</small>
        } else {
            after = <small className="text-muted">{time.format("HH:mm")}</small>
        }
    }
    let name
    if ("done" in item && item.done) {
        name = <s className="text-muted">{item.name}</s>
    } else {
        name = item.name
    }
    return <span>{name} {after}</span>
}

export function getIngredientText(item) {
    let text = item.name
    if (!("stats" in item)) {
        return text + " INVALID STATS"
    }

    if ("kcal" in item["stats"]) {
        text += " " + item["stats"]["energy-kcal_100g"] + "kcal"
    }
    if ("carbs" in item["stats"]) {
        text += " " + item["stats"]["carbohydrates_100g"] + "c"
    }
    if ("fats" in item["stats"]) {
        text += " " + item["stats"]["fat_100g"] + "f"
    }
    if ("proteins" in item["stats"]) {
        text += " " + item["stats"]["proteins_100g"] + "p"
    }
    return text
}

export function getRecipeText(item) {
    let text = item.name + "("

    if ("ingredients" in item) {
        for (let ingredient of item["ingredients"]) {
            text += ingredient["amount"] + ingredient["unit"] + " " + ingredient["name"] + ", "
        }
    }

    return text.slice(0, -2) + ")"
}

export function getFoodplanItemText(item) {
    return item["amount"] + item["unit"] + " " + item.name
}

export function addUpDictsWithMultiplier(dict1, dict2, multiplier) {
    if (!dict2) {
        return dict1
    }

    let newDict = {}
    Object.assign(newDict, dict1)

    for (let entry of Object.entries(dict2)) {
        if (newDict[entry[0]]) {
            newDict[entry[0]] += entry[1] * multiplier
        } else {
            newDict[entry[0]] = entry[1] * multiplier
        }
    }

    return newDict
}

export function addUpDicts(dict1, dict2) {
    return addUpDictsWithMultiplier(dict1, dict2, 1)
}

export function convertDBItem(item) {
    let newItem = {}

    const must_contain = ["product_name", "_id", "nutriments"]
    for (let key of must_contain) {
        if (!(key in item) || item[key] === null || item[key] === "") {
            console.warn("Invalid Item, missing \"" + key + "\" or empty.")
            console.warn(item)
            return null
        }
    }

    newItem["name"] = item["product_name"]
    if (item["image_front_small_url"]) {
        newItem["img_url"] = item["image_front_small_url"]
    }

    newItem["db_id"] = item["_id"]
    if (item["serving_size"]) {
        if (item["serving_size"].endsWith("g") || item["serving_size"].endsWith("ml")) {
            newItem["serving_size_amount"] = parseFloat(item["serving_size"].split('g')[0])
            newItem["serving_size_unit"] = "g"
        } else if (!isNaN(item["serving_size"])) {
            newItem["serving_size_amount"] = parseFloat(item["serving_size"])
            console.warn("Serving size assumed as g: " + item["serving_size"])
            newItem["serving_size_unit"] = "g"
        } else {
            console.warn("Invalid serving size \"" + item["serving_size"] + "\", setting 100g")
            newItem["serving_size_amount"] = 100.0
            newItem["serving_size_unit"] = "g"
        }
    }
    newItem["stats"] = {}

    for (let nutriment of nutriments) {
        if (item["nutriments"][nutriment + "_prepared_100g"]) {
            newItem["stats"][nutriment + "_100g"] = item["nutriments"][nutriment + "_prepared_100g"]
        } else if (item["nutriments"][nutriment + "_100g"]) {
            newItem["stats"][nutriment + "_100g"] = item["nutriments"][nutriment + "_100g"]
        }
        if (item["nutriments"][nutriment + "_prepared_serving"]) {
            newItem["stats"][nutriment + "_serving"] = item["nutriments"][nutriment + "_prepared_serving"]
        } else if (item["nutriments"][nutriment + "_serving"]) {
            newItem["stats"][nutriment + "_serving"] = item["nutriments"][nutriment + "_serving"]
        }

    }

    if ("keywords" in item) {
        newItem["alternative_names"] = item["keywords"].join(";")
    } else {
        newItem["alternative_names"] = ""
    }

    return newItem;
}

export function calculateStatsFromAmount(amount, unit, ingredient) {
    let stats = {}
    if (unit === "x") {
        for (let nutriment of nutriments) {
            if (ingredient["stats"][nutriment + "_serving"] !== null) {
                stats[nutriment] = ingredient["stats"][nutriment + "_serving"] * amount
            } else if (ingredient["serving_size_amount"] && ingredient["serving_size_unit"] && ingredient["serving_size_unit"] === "g" && ingredient["stats"][nutriment + "_100g"] !== null) {
                stats[nutriment] = (ingredient["stats"][nutriment + "_100g"] / 100.) * ingredient["serving_size"] * amount
            }
        }
        if (ingredient["serving_size_amount"] && ingredient["serving_size_unit"] && ingredient["serving_size_unit"] === "g") {
            stats["grams"] = amount * ingredient["serving_size_amount"]
        }
    } else if (unit === "g") {
        for (let nutriment of nutriments) {
            if (ingredient["stats"][nutriment + "_100g"] !== null) {
                stats[nutriment] = amount * (ingredient["stats"][nutriment + "_100g"] / 100.)
            } else if (ingredient["serving_size_amount"] && ingredient["serving_size_unit"] && ingredient["serving_size_unit"] === "g" && ingredient["stats"][nutriment + "_serving"] !== null) {
                stats[nutriment] = 100. * (ingredient["stats"][nutriment + "_serving"] / ingredient["serving_size"])
            }
        }

        stats["grams"] = amount
    } else {
        console.error("Invalid unit: " + unit)
        return undefined
    }
    if (stats["energy-kcal"] !== null) {
        return stats
    } else {
        console.error("Invalid stats: " + JSON.stringify(stats))
        return undefined
    }
}

export function dictEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export const nutriments = ["energy", "energy-kcal", "proteins", "casein", "serum-proteins", "nucleotides", "carbohydrates", "sugars", "sucrose", "glucose", "fructose", "lactose", "maltose", "maltodextrins", "starch", "polyols", "fat", "saturated-fat", "butyric-acid", "caproic-acid", "caprylic-acid", "capric-acid", "lauric-acid", "myristic-acid", "palmitic-acid", "stearic-acid", "arachidic-acid", "behenic-acid", "lignoceric-acid", "cerotic-acid", "montanic-acid", "melissic-acid", "monounsaturated-fat", "polyunsaturated-fat", "omega-3-fat", "alpha-linolenic-acid", "eicosapentaenoic-acid", "docosahexaenoic-acid", "omega-6-fat", "linoleic-acid", "arachidonic-acid", "gamma-linolenic-acid", "dihomo-gamma-linolenic-acid", "omega-9-fat", "oleic-acid", "elaidic-acid", "gondoic-acid", "mead-acid", "erucic-acid", "nervonic-acid", "trans-fat", "cholesterol", "fiber", "sodium", "alcohol", "vitamin-a", "vitamin-d", "vitamin-e", "vitamin-k", "vitamin-c", "vitamin-b1", "vitamin-b2", "vitamin-pp", "vitamin-b6", "vitamin-b9", "vitamin-b12", "biotin", "pantothenic-acid", "silica", "bicarbonate", "potassium", "chloride", "calcium", "phosphorus", "iron", "magnesium", "zinc", "copper", "manganese", "fluoride", "selenium", "chromium", "molybdenum", "iodine", "caffeine", "taurine",]

export function formatStats(stats) {
    if (stats === undefined) {
        return {
            energy_serving: "n/a",
            energy_100g: "n/a",
            fat_serving: "n/a",
            fat_100g: "n/a",
            carbohydrates_serving: "n/a",
            carbohydrates_100g: "n/a",
            proteins_serving: "n/a",
            proteins_100g: "n/a",
        }
    }

    let energy_serving = "n/a";
    let energy_100g = "n/a";

    for (let nutriment of nutriments) {
        for (let nutriment_key of [nutriment, nutriment + "_100g", nutriment + "_serving"]) {
            if (nutriment_key in stats && stats[nutriment_key] !== null &&
                typeof stats[nutriment_key] != "number"
            ) {
                stats[nutriment_key] = parseFloat(stats[nutriment_key])
            }
        }
    }

    if ("energy-kcal" in stats && stats["energy-kcal"] !== null) {
        energy_serving = stats["energy-kcal"].toFixed(2) + " kcal"
    }
    if ("energy-kcal_100g" in stats && stats["energy-kcal_100g"] !== null) {
        energy_100g = stats["energy-kcal_100g"].toFixed(1) + " kcal"
    }
    if ("energy-kcal_serving" in stats && stats["energy-kcal_serving"] !== null) {
        energy_serving = stats["energy-kcal_serving"].toFixed(1) + " kcal"
    }

    let fat_serving = "n/a";
    let fat_100g = "n/a";
    if ("fat" in stats && stats["fat"] !== null) {
        fat_serving = stats["fat"].toFixed(2) + " g"
    }
    if ("fat_100g" in stats && stats["fat_100g"] !== null) {
        fat_100g = stats["fat_100g"].toFixed(1) + " g"
    }
    if ("fat_serving" in stats && stats["fat_serving"] !== null) {
        fat_serving = stats["fat_serving"].toFixed(1) + " g"
    }

    let carbohydrates_serving = "n/a";
    let carbohydrates_100g = "n/a";
    if ("carbohydrates" in stats && stats["carbohydrates"] !== null) {
        carbohydrates_serving = stats["carbohydrates"].toFixed(2) + " g"
    }
    if ("carbohydrates_100g" in stats && stats["carbohydrates_100g"] !== null) {
        carbohydrates_100g = stats["carbohydrates_100g"].toFixed(1) + " g"
    }
    if ("carbohydrates_serving" in stats && stats["carbohydrates_serving"] !== null) {
        carbohydrates_serving = stats["carbohydrates_serving"].toFixed(1) + " g"
    }

    let proteins_serving = "n/a";
    let proteins_100g = "n/a";
    if ("proteins" in stats && stats["proteins"] !== null) {
        proteins_serving = stats["proteins"].toFixed(2) + " g";
    }
    if ("proteins_100g" in stats && stats["proteins_100g"] !== null) {
        proteins_100g = stats["proteins_100g"].toFixed(1) + " g";
    }
    if ("proteins_serving" in stats && stats["proteins_serving"] !== null) {
        proteins_serving = stats["proteins_serving"].toFixed(1) + " g";
    }

    // Score is protein * 1000 / kcal and
    let score = 0;
    let scorehtml = "n/a";
    if (stats["proteins"] !== null && stats["energy-kcal"] !== null && stats["energy-kcal"] > 0) {
        score = stats["proteins"] * 1000 / stats["energy-kcal"]
        // colored gradient from green to red
        // red is 0 and 65 is green and 300 deep green
        score = Math.min(300, Math.max(0, score))
        let r = Math.min(255, Math.max(0, 255 - score * 255 / 65))
        let g = Math.min(255, Math.max(0, score * 255 / 65))
        scorehtml = <span style={{color: "rgb(" + r + "," + g + ",0)"}}>{score.toFixed(1)}</span>
    }

    return {
        energy_serving: energy_serving,
        energy_100g: energy_100g,
        fat_serving: fat_serving,
        fat_100g: fat_100g,
        carbohydrates_serving: carbohydrates_serving,
        carbohydrates_100g: carbohydrates_100g,
        proteins_serving: proteins_serving,
        proteins_100g: proteins_100g,
        score: scorehtml
    }
}

export function formatStats_short(stats) {
    const nutriments = ["energy-kcal", "fat", "carbohydrates", "proteins"];
    const result = {};

    nutriments.forEach(nutriment => {
        ["", "_100g", "_serving"].forEach(suffix => {
            const key = nutriment + suffix;
            if (key in stats && stats[key] !== null && typeof stats[key] !== "number") {
                stats[key] = parseFloat(stats[key]);
            }
        });

        result[`${nutriment}_serving`] = stats[`${nutriment}_serving`] !== undefined ? stats[`${nutriment}_serving`].toFixed(1) + (nutriment === "energy-kcal" ? " kcal" : " g") : "n/a";
        result[`${nutriment}_100g`] = stats[`${nutriment}_100g`] !== undefined ? stats[`${nutriment}_100g`].toFixed(1) + (nutriment === "energy-kcal" ? " kcal" : " g") : "n/a";
    });

    return result;
}
