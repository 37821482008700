import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {addUpDictsWithMultiplier, calculateStatsFromAmount} from "../../helpers/helpers";
import {Col, Container, InputGroup, Row} from "react-bootstrap";
import Omnibar from "../Omnibar";
import {fetchIngredients, submitRecipe} from "../../api/foodApiHandler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {AddButton} from "../cards/food/AddFoodPlanItem";
import {useMutation, useQuery, useQueryClient} from "react-query";

// Takes a list of ingredients and adds up the stats for the given amount
export function addUpRecipeStats(amount, ingredients, ingredientsDict) {
    let totalStats = {
        grams: 0,
        carbohydrates: 0,
        fat: 0,
        proteins: 0,
        "energy-kcal": 0
    }
    for (let ingredient of ingredients) {
        let stats = ingredient["ingredient"] ? ingredient["ingredient"] : ingredientsDict[ingredient["name"]]
        if (!stats) {
            console.error(ingredient["name"] + " not found!")
            return {}
        }
        totalStats = addUpDictsWithMultiplier(totalStats, calculateStatsFromAmount(ingredient["amount"], ingredient["unit"], stats), amount);
    }

    for (let nutrient of ["carbohydrates", "fat", "proteins", "energy-kcal"]) {
        totalStats[nutrient + "_100g"] = totalStats[nutrient] / (totalStats["grams"] / 100.0)
    }

    return totalStats
}

export default function AddRecipeModal({
                                           show, onClose, editData, setEditData
                                       }) {
    const [amount, setAmount] = React.useState("0")
    const [unit, setUnit] = React.useState("g")
    const queryClient = useQueryClient()

    const submitRecipeMutation = useMutation({
        mutationFn: submitRecipe,
        onSuccess: () => {
            queryClient.invalidateQueries('recipes')
        }
    })

    const ingredients = useQuery({
        queryKey: ['food', 'ingredients'],
        queryFn: fetchIngredients
    })

    function addIngredient(entry, item) {
        if (!item) {
            throw new Error("Please select an item from the list")
        }
        if (!(item["name"] in ingredients.data)) {
            throw new Error("Item not found!")
        }
        let parsedItem = {
            amount: amount,
            unit: unit,
            name: item["name"],
            ingredient: ingredients[item["name"]],
        }

        let newIngredients = editData.ingredients
        newIngredients.push(parsedItem)
        setEditData({
            ...editData,
            ingredients: newIngredients
        })
    }

    function close() {
        setEditData({
            ingredients: [], name: "", unit: "g", amount: "0"
        })
        return onClose()
    }

    if (!ingredients.isSuccess) {
        return <Modal size="xl" show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>New Recipe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>Loading ingredients...</div>
            </Modal.Body>
        </Modal>
    }

    let totalStats = addUpRecipeStats(1, editData.ingredients, ingredients.data)

    return <Modal size="xl" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>New Recipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Recipe name</Form.Label>
                    <Form.Control
                        onChange={(event) => setEditData({
                                ...editData,
                                name: event.target.value
                            }
                        )}
                        value={editData.name}
                        placeholder=""
                        autoFocus
                    />
                </Form.Group>
                <Col>
                    <InputGroup>
                        <div style={{width: '80%'}}>
                            <Omnibar placeholder={"New Ingredient..."}
                                     recommendations={Object.values(ingredients.data)}
                                     onSubmit={addIngredient.bind(this)}
                                     resizeable={true}
                                     onSelect={(item) => {
                                         setEditData({
                                             ...editData,
                                             amount: item["serving_size_amount"],
                                             unit: item["serving_size_unit"]
                                         })
                                     }}
                            />
                        </div>

                        <Form.Control value={amount} onChange={
                            (event) => {
                                let newUnit = unit
                                let newAmount = event.target.value
                                if (event.target.value.endsWith("g")) {
                                    newUnit = "g"
                                    newAmount = event.target.value.substring(0, event.target.value.length - 1)
                                } else if (event.target.value.endsWith("x")) {
                                    newUnit = "x"
                                    newAmount = event.target.value.substring(0, event.target.value.length - 1)
                                }
                                setAmount(newAmount)
                                setUnit(newUnit)
                            }
                        } style={{height: 100 + '%'}}/>
                        <InputGroup.Text id="basic-addon1">{unit}</InputGroup.Text>
                    </InputGroup>
                </Col>
                <br/>

                <Container fluid>
                    <Row>
                        <Col xs={"auto"} style={{color: "white"}}>__</Col>
                        <Col xs={5}>Zutat</Col>
                        <Col xs={2}>Amount</Col>
                        <Col xs={1}>kcal</Col>
                        <Col xs={1}>carbs</Col>
                        <Col xs={1}>fats</Col>
                        <Col xs={1}>proteins</Col>
                    </Row>
                    <hr/>
                    {editData.ingredients.map((ingredient, index) => {
                        let stats = calculateStatsFromAmount(ingredient["amount"],
                            ingredient["unit"], ingredient["ingredient"] ? ingredient["ingredient"] : ingredients.data[ingredient["name"]])
                        if (!stats) {
                            return "Could not calculate stats because nutrition information is missing."
                        }
                        return <Row key={"ingredients " + index}>
                            <Col xs={"auto"}><AddButton title={"Remove from recipe"} onClick={() => {
                                let newIngredients = editData.ingredients
                                newIngredients.splice(index, 1)
                                setEditData({
                                    ...editData,
                                    ingredients: newIngredients
                                })
                            }}>
                                <FontAwesomeIcon icon={solid('minus')}/>
                            </AddButton></Col>
                            <Col xs={5}>{ingredient["name"]}</Col>
                            <Col xs={2}>{ingredient["amount"] + ingredient["unit"]}</Col>
                            <Col xs={1}>{stats["energy-kcal"].toFixed(2)}kcal</Col>
                            <Col xs={1}>{stats["carbohydrates"].toFixed(2)}g</Col>
                            <Col xs={1}>{stats["fat"].toFixed(2)}g</Col>
                            <Col xs={1}>{stats["proteins"].toFixed(2)}g</Col>
                        </Row>;
                    })}
                    <hr/>
                    <Row>
                        <Col xs={"auto"} style={{color: "white"}}>__</Col>
                        <Col xs={5}>{"Gesamt"}</Col>
                        <Col xs={2}>{totalStats["grams"].toFixed(2)}g</Col>
                        <Col xs={1}>{totalStats["energy-kcal"].toFixed(2)}kcal</Col>
                        <Col xs={1}>{totalStats["carbohydrates"].toFixed(2)}g</Col>
                        <Col xs={1}>{totalStats["fat"].toFixed(2)}g</Col>
                        <Col xs={1}>{totalStats["proteins"].toFixed(2)}g</Col>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => close()}>
                Cancel
            </Button>
            <Button variant="primary" onClick={() => {
                let recipe = {"name": editData.name, "ingredients": []}
                if (editData._id) {
                    recipe["_id"] = editData._id[["$oid"]]
                }

                for (let newIng of editData.ingredients) {
                    recipe["ingredients"].push({
                        "name": newIng["name"], "unit": newIng["unit"], "amount": newIng["amount"]
                    })
                }

                submitRecipeMutation.mutate(recipe)
                close()
            }}>
                Save
            </Button>


        </Modal.Footer>
    </Modal>;

}