import React, {forwardRef} from "react";
import DatePicker from "react-datepicker";
import {jsDateToMoment} from "../helpers/dateHelper";

const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const CustomInput = forwardRef(({value, onClick}, ref) => (
    <button style={{
        cursor: 'pointer',
        padding: '5px 15px',
        border: '0',
        borderRadius: '4px',
        backgroundColor: '#216ba5',
        font: 'inherit',
        color: '#fff'
    }} onClick={onClick} ref={ref}>
        {days[new Date(value).getDay()]}, {new Date(value).toLocaleDateString('de-DE')}
    </button>
));

export function DatePickerWithButtons(props) {
    return <span>
                        <button style={{marginLeft: ".5em", float: "right"}} className="btn btn-sm"
                                onClick={() => {
                                    const newDate = props.currentDate.add(props.daysPerClick, "days")
                                    props.changeDate(newDate)
                                }}>
                        <i className="fas fa-caret-right"/>
                        </button>
                        <div style={{display: "inline-block", float: "right"}}>
                            <DatePicker selected={new Date(props.currentDate.format("YYYY/MM/DD HH:mm:ss"))}
                                        onChange={(date) => props.changeDate(jsDateToMoment(date))}
                                        todayButton="Today"
                                        customInput={<CustomInput/>}/>
                        </div>
                        <button style={{marginLeft: ".5em", float: "right"}} className="btn btn-sm"
                                onClick={() => {
                                    const newDate = props.currentDate.subtract(props.daysPerClick, "days")
                                    props.changeDate(newDate)
                                }}>
                            <i className="fas fa-caret-left"/>
                        </button>
                    </span>;
}

DatePickerWithButtons.defaultProps = {
    daysPerClick: 1
}