var moment = require('moment');

export const MORNING_TIME = {hour: 4, minute: 30, second: 0, millisecond: 0};

export function getCurrentTime() {
    return moment().utc().local();
}

export function jsDateToMoment(date) {
    return moment.utc(date).local()
}

export function isBeforeDayStart(moment_time) {
    return moment_time.hour() < MORNING_TIME.hour || (moment_time.hour() === MORNING_TIME.hour && moment_time.minute() < MORNING_TIME.minute)
}

export function getMorningTimeFromDate(time) {
    if (isBeforeDayStart(time)) {
        time = time.subtract(1, "days")
    }

    return time.set(MORNING_TIME)
}

export function getMorningTime() {
    return getMorningTimeFromDate(getCurrentTime())
}

export function isToday(date) {
    const today = getCurrentTime()
    return date.date() === today.date() &&
        date.month() === today.month() &&
        date.year() === today.year()
}

export function mongoToMoment(dict) {
    return moment.utc(dict["$date"]).local()
}

export function momentToMongo(moment_time) {
    return {"$date": moment_time.toISOString()}
}

export function isOverdue(todo) {
    return !todo["done"] && ("planned_after" in todo && todo["planned_after"] != null && mongoToMoment(todo.planned_after) < getMorningTime());
}