import React from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from 'react-bootstrap/Tooltip';
import {OverlayTrigger} from "react-bootstrap";

function HoverableInfoIcon(iconProps) {
    const renderTooltip = (text, props) => (<Tooltip id="button-tooltip" {...props}>
        {text}
    </Tooltip>);

    return <OverlayTrigger
        placement="right"
        delay={{show: 250, hide: 400}}
        overlay={(props) => renderTooltip(iconProps.text, props)}
    >
        <FontAwesomeIcon icon={solid("info-circle")}/>
    </OverlayTrigger>
}

export default HoverableInfoIcon;