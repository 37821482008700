import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Draggable} from "@hello-pangea/dnd";
import {durationToString, getTodoText, priorityToColor, stringToColor} from "../../helpers/helpers";
import {parseTask, unparseTask} from "../../parser/TaskParser";
import ReactTooltip from "react-tooltip";
import {deleteEntity, updateItem} from "../../api/entityApiHandler";
import {useMutation, useQueryClient} from "react-query";

const TodoBox = styled.div`
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 8px;
    ${({priority}) => (priority && priority !== 0) ? "border-left: 3px solid " + priorityToColor(priority) : "padding-left: 10px"};
    margin: 4px;
    background: white;
    color: black;
    position: relative;
`

const Text = styled.div`
`

export const Footer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
`

const BackgroundImage = styled.img`
    position: absolute;
    height: 40px;
    opacity: 0.1;
    top: 0;
    right: 2px;
    padding: 4px;
`

const ProjectTag = styled.small`
    padding: 5px;
    background-color: ${({color}) => color};
    color: rgb(255, 255, 255);
    flex-grow: 0;
    border-radius: 2px;
`

const DurationTag = styled.small`
    color: #6B778C;
    text-align: right;
    flex-grow: 1;
    flex-shrink: 1;
`

const backgrounds = {
    "jp": "https://upload.wikimedia.org/wikipedia/commons/3/35/Nihon.png",
    "sport": "sport.svg",
    "uni": "uni.svg",
    "tcweb": "plane-departure.svg",
    "work": "briefcase.svg",
    "arbeit": "briefcase.svg",
    "doctor": "stethoscope.svg",
    "arzt": "stethoscope.svg",
    "gesundheit": "stethoscope.svg",
    "health": "stethoscope.svg",
    "haushalt": "broom.svg",
    "hausarbeit": "broom.svg",
    "fahrschule": "car.svg",
    "hack": "hacker.svg",
    "hacking": "hacker.svg",
    "finanzen": "coins.svg",
    "finance": "coins.svg",
    "freetime": "happy.svg",
    "freizeit": "happy.svg",
    "test": "test.svg",
    "lisa": "heart.svg",
    "jakob": "heart.svg",
}

function DraggableTodo(props) {
    const [editMode, setEditMode] = useState(false)
    const [text, setText] = useState("")
    const tooltip = useRef(null)
    const queryClient = useQueryClient()

    const mutateItem = useMutation({
        mutationFn: updateItem,
        onSuccess: () => {
            queryClient.invalidateQueries(["todos"])
        }
    })

    const deleteEntityMutation = useMutation({
        mutationFn: deleteEntity, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    const handleClick = (e) => {
        if (e.detail === 2) {
            setEditMode(true)
            setText(unparseTask(props.todo))
        }
    };

    const handleKey = event => {
        if (event.keyCode === 27) {
            event.preventDefault();
            setEditMode(false)
            setText("")
        }
    }

    const updateText = (e) => {
        setText(e.target.value)
    }

    let inner
    if (editMode) {
        inner = provided => (
            <TodoBox
                priority={props.todo.priority}
                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <form
                    onSubmit={(e) => {
                        try {
                            e.preventDefault()
                            if (text !== "") {
                                const words = text.split(' ')
                                let modifiedTask = parseTask(words);
                                for (const key in props.todo) {
                                    if (!(key in modifiedTask)) {
                                        modifiedTask[key] = null
                                    }
                                }
                                modifiedTask["_id"] = props.todo["_id"]
                                modifiedTask["owner"] = props.todo["owner"]
                                modifiedTask["type"] = props.todo["type"]


                                mutateItem.mutate(modifiedTask)
                                setText("")
                                setEditMode(false)
                            } else {
                                deleteEntityMutation.mutate(props.todo)
                                tooltip.current.setAttribute("data-tip", "Todo deleted")
                                tooltip.current.setAttribute("data-type", "info")
                                ReactTooltip.show(tooltip.current)
                                setTimeout(() => {
                                    setText("")
                                    setEditMode(false)
                                    ReactTooltip.hide(tooltip.current)
                                }, 300)
                            }
                        } catch (e) {
                            tooltip.current.setAttribute("data-tip", e.toString())
                            tooltip.current.setAttribute("data-type", "error")
                            ReactTooltip.show(tooltip.current)
                        }
                    }
                    }
                    className={"mr-auto w-100 navbar-search"}
                >
                    <ReactTooltip type={"error"}/>
                    <p ref={ref => tooltip.current = ref} data-tip={"Unknown Error"}></p>
                    <div className="input-group">
                        <input type="text" className="form-control bg-light border-0 small"
                               placeholder={props.placeholder}
                               onInput={updateText}
                               value={text}
                               onKeyDown={handleKey}
                               autoFocus
                        />
                    </div>
                </form>
            </TodoBox>)
    } else {
        inner = provided => (
            <TodoBox
                priority={props.todo.priority}
                onClick={handleClick}
                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                <Text>{getTodoText(props.todo)}</Text>
                <Footer>
                    {"project" in props.todo && props.todo.project in backgrounds ?
                        <BackgroundImage
                            src={backgrounds[props.todo.project]}/> : "project" in props.todo ?
                            <ProjectTag color={stringToColor(props.todo.project)}>
                                {props.todo.project}
                            </ProjectTag> : ""}
                    {"duration" in props.todo ? <DurationTag>
                        {durationToString(props.todo.duration)}
                    </DurationTag> : ""}
                </Footer>
            </TodoBox>)
    }
    return <Draggable draggableId={props.uniquename} index={props.index}>
        {inner}
    </Draggable>
}

export default DraggableTodo;