import React from 'react';
import TaskListCard from "../../cards/TaskListCard";
import {getCurrentTime} from "../../../helpers/dateHelper";
import {useQuery} from "react-query";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";
import {btnoptions} from "../../ui/ButtonList";

function Problems() {
    const date = getCurrentTime();

    const problems = useQuery({
        queryKey: 'problems',
        queryFn: () => {
            const requestOptions = {
                method: 'GET',
                headers: authHeader({'Content-Type': 'application/json'}),
            };
            return fetch(process.env.REACT_APP_API_URL + '/todos/broken', requestOptions)
                .then(handleResponse).then((data) => {
                    return data;
                })
        }
    });

    return (
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Potential problems</h1>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={problems["time_problems"]}
                                  title={"Planned time and start time more than one day apart (and not done)"}
                                  options={[btnoptions.REPAIR, btnoptions.DELETE]}/>
                </div>
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={problems["old_undone"]}
                                  title={"Old todos which are not checked off"}
                                  options={[btnoptions.CHECK_OFF_TODO, btnoptions.DELETE]}/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={problems["too_long"]}
                                  title={"Too long todos"}
                                  options={[btnoptions.DELETE]}/>
                </div>
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={problems["missing_fields"]}
                                  title={"Todos missing fields (owner or done)"}
                                  options={[btnoptions.CHECK_OFF_TODO, btnoptions.DELETE]}/>
                </div>
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={problems["unknown_task_type"]}
                                  title={"Strange task type"}
                                  options={[btnoptions.CHECK_OFF_TODO, btnoptions.DELETE]}/>
                </div>
            </div>
        </div>
    );
}

export default Problems;