import React from 'react';
import ProjectCard from "../../cards/ProjectCard";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useQuery} from "react-query";

export const HABITUAL_BOOLEAN = 0;

export function Goals(props) {

    const projects = useQuery({
        queryKey: 'projects',
        queryFn: () => {
            return fetch(process.env.REACT_APP_API_URL + '/projects', {
                method: 'GET', headers: authHeader({'Content-Type': 'application/json'})
            }).then(handleResponse).then(data => {
                return data;
            })
        }
    })


    if (!projects.isSuccess) {
        return <div>Loading...</div>
    }


    const goals = projects.data.filter(prj => "measurement_type" in prj).map((project, index) =>
        <div key={index} className="col-lg-6">
            <ProjectCard project={project} showGoalModal={props.showGoalModal}/>
        </div>
    )
    const non_goals = projects.data.filter(prj => !("measurement_type" in prj)).map((project, index) =>
        <span key={index}>{project.id}</span>
    )

    return <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Tracked Goals</h1>
            <button className="btn btn-primary" onClick={(event) => {
                event.preventDefault()
                props.showGoalModal()
            }}>Add a new goal <FontAwesomeIcon icon={solid('flag-checkered')}/></button>
        </div>

        <div className="row">
            {goals}
        </div>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Projects</h1>
        </div>

        <div className="row">
            {non_goals}
        </div>

    </div>
}