const randomTodos = ["Write a love letter to a cheese sandwich",
    "Practice my disco nap",
    "Create a life-size replica of the Eiffel Tower out of popsicle sticks",
    "Train my cat to do my taxes",
    "Build a time machine (cake not included)",
    "Write a symphony for kazoo and tuba",
    "Make a list of all the things that are round and then square them",
    "Invent a new dance move and name it the 'Couch Potato Cha-cha'",
    "Ride a unicycle across the Sahara Desert",
    "Shave the head of a bald eagle",
    "Teach a dog to bark in Spanish",
    "Learn to speak fluent Klingon",
    "Obtain a driver's license for a horse",
    "Go to a library and try to check out a book with my nose",
    "Go to a field and start a conga line with the cows",
    "Create a dance move called the 'Taco Twist'",
];

export const generateTodo = function () {
    return randomTodos[Math.floor(Math.random() * randomTodos.length)];
}