import React from 'react';

function Commands() {
    return (<div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Commands</h1>
        </div>

        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                <div className="row" style={{margin: 0}}>
                                    <div className="col-sm-12">
                                        <table className="table table-bordered dataTable" id="dataTable"
                                               role="grid" aria-describedby="dataTable_info"
                                               cellSpacing="0">
                                            <thead>
                                            <tr role="row">
                                                <th className="sorting sorting_asc" tabIndex="0"
                                                    aria-controls="dataTable" rowSpan="1" colSpan="1"
                                                    style={{width: 5 + '%'}} aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending">Name
                                                </th>
                                                <th className="sorting" tabIndex="0" aria-controls="dataTable"
                                                    rowSpan="1" colSpan="1" style={{width: 3 + '%'}}
                                                    aria-label="Position: activate to sort column ascending">Symbol
                                                </th>
                                                <th className="sorting" tabIndex="0" aria-controls="dataTable"
                                                    rowSpan="1" colSpan="1" style={{width: 25 + '%'}}
                                                    aria-label="Office: activate to sort column ascending">Description
                                                </th>
                                                <th className="sorting" tabIndex="0" aria-controls="dataTable"
                                                    rowSpan="1" colSpan="1" style={{width: 15 + '%'}}
                                                    aria-label="Office: activate to sort column ascending">Valid
                                                    values
                                                </th>
                                                <th className="sorting" tabIndex="0" aria-controls="dataTable"
                                                    rowSpan="1" colSpan="1" style={{width: 15 + '%'}}
                                                    aria-label="Age: activate to sort column ascending">Examples
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Planned time</td>
                                                <td><code>></code></td>
                                                <td>Use this command to set the earliest time at which you want
                                                    to do
                                                    the task
                                                </td>
                                                <td>
                                                    <code> 10.05.> </code>, <code> Mo> </code>, <code> 10:30> </code>, <code> > </code>, <code> 10.06.2022_18:30> </code>
                                                </td>
                                                <td><code>Do the dishes 10:30> !!</code></td>
                                            </tr>
                                            <tr>
                                                <td>Start time</td>
                                                <td>n/a</td>
                                                <td>This command sets the time when you actually started to
                                                    do the task or when an event begins
                                                </td>
                                                <td>
                                                    <code> 10.05. </code>, <code> Monday </code>, <code> 10:30 </code>, <code> 10.06.2022_18:30 </code>
                                                </td>
                                                <td><code>Gala-Dinner 20:30</code></td>
                                            </tr>
                                            <tr>
                                                <td>Duration</td>
                                                <td>n/a</td>
                                                <td>Use this command to set how long a event is or how long you expect a
                                                    task to take.
                                                </td>
                                                <td>
                                                    <code> 10m </code>, <code> 1h </code>, <code> 1h30m </code>
                                                </td>
                                                <td><code>Cook diner 50m 20:00></code></td>
                                            </tr>
                                            <tr>
                                                <td>Project</td>
                                                <td><code>#</code></td>
                                                <td>Use this command to assign this task to a project.</td>
                                                <td>
                                                    <code> #work </code>, <code> #uni </code>, <code> #project.subproject </code>
                                                </td>
                                                <td><code>Do homework #school !</code></td>
                                            </tr>
                                            <tr>
                                                <td>Priority</td>
                                                <td><code>!, ?</code></td>
                                                <td>Use this command to set how important the task is. (More
                                                    question marks => less important, more exclamation marks =>
                                                    more important)
                                                </td>
                                                <td>
                                                    <code> ??? </code>, <code> ?? </code>, <code> ? </code>, <code> ! </code>, <code> !! </code>, <code> !!! </code>
                                                </td>
                                                <td><code>Clean up ??</code></td>
                                            </tr>
                                            <tr>
                                                <td>Multi-todos</td>
                                                <td><code>x</code></td>
                                                <td>Use this command to create multiple todos or events which
                                                    occur multiple times. The syntax
                                                    is <code>x[number][unit][distance]</code>, where number is
                                                    how many events you want to create, unit is the unit in
                                                    which you want to specify the distance between each event or
                                                    task
                                                    ("mi" for minutes, "h" for hours, "d" for days, "mo" for
                                                    months, "y" for years and nothing to create all without changing
                                                    the date) and distance is the
                                                    distance distance between each event or task. The distance can
                                                    be left away if it is equal to 1.
                                                </td>
                                                <td>
                                                    <code> x5d4 </code>,<code> x5w </code>,<code> x10 </code>
                                                </td>
                                                <td><code> Do sports 10:00> x5w 30m</code> to create a sports task
                                                    every week from today for 5
                                                    weeks <br/> <code> Lift weights x10 </code> to create 10 tasks in
                                                    the inbox without date <br/> <code> Drink water 15:00
                                                        x10mi30 </code> to schedule drinking water every 30 minutes for
                                                    5 hours
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Done</td>
                                                <td><code>~</code></td>
                                                <td>Use this command to mark the command as already done</td>
                                                <td>
                                                    <code> ~ </code>
                                                </td>
                                                <td><code>Wake up 9:00 ~</code></td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>Repetitions</td>*/}
                                            {/*    <td><code>&</code></td>*/}
                                            {/*    <td>Coming soon...</td>*/}
                                            {/*    <td>*/}
                                            {/*        <code> &5d </code>, <code> &2w </code>*/}
                                            {/*    </td>*/}
                                            {/*    <td><code>Karate 15._10:00 &1w</code></td>*/}
                                            {/*</tr>*/}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Commands;
