import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import * as PropTypes from "prop-types";
import {DatePickerWithButtons} from "../DatepickerWithButtons";

DatePickerWithButtons.propTypes = {
    onClick: PropTypes.func,
    currentDate: PropTypes.any,
    onChange: PropTypes.func,
    onClick1: PropTypes.func
};

function DatepickerCard(props) {
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary"
                    style={{display: 'inline'}}>{props.title + (props.empty ? " (Empty)" : "")}</h6>
                <DatePickerWithButtons changeDate={props.changeDate} currentDate={props.currentDate}/>
            </div>
            {
                !props.empty
                    ?
                    <div className="card-body">
                        {props.children}
                    </div> : null
            }
        </div>
    );
}

export default DatepickerCard;