import React, {useMemo, useState} from 'react';
import {InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {fetchMoney, submitMoneyItem} from "../../../api/moneyApiHandler";
import {mongoToMoment} from "../../../helpers/dateHelper";
import {useMutation, useQuery} from "react-query";

export function Money() {
    const [text, setText] = useState("");
    const [payee, setPayee] = useState("");
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [category, setCategory] = useState("food");
    const submitMoneyItemMutation = useMutation(submitMoneyItem);

    const billsQuery = useQuery('bills', () => {
        let data = fetchMoney();
        for (let i = 0; i < data.length; i++) {
            data[i].date = mongoToMoment(data[i].date).format("DD.MM.YYYY")
        }
        return data;
    });

    const total = useMemo(() => {
        if (!billsQuery.isSuccess) {
            return 0;
        }
        billsQuery.data.reduce((total, item) => {
            if (item.payee === "jakob") {
                return total + item.amount;
            } else {
                return total - item.amount;
            }
        }, 0);
    }, [billsQuery]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    }

    const submit = () => {
        let amount = parseFloat(text.replace(",", "."));
        if (isNaN(amount)) {
            alert("Das ist keine Zahl xD Bitte kein Eurozeichen verwenden, Komma oder Punkt ist egal");
            return;
        }
        let payee = payee;
        if (payee === "") {
            alert("Bitte auswählen wer bezahlt hat ;)");
            return;
        }
        let date = date;
        let category = category;

        submitMoneyItemMutation.mutate({amount, payee, date, category});
    }

    if (billsQuery.isLoading) {
        return <div>Loading...</div>
    }
    let bills = billsQuery.data;
    let listItems = bills.map((item, index) =>
        <div className="card mb-1" key={index}>
                    <span className="card-body">
                        {item.payee} paid {item.amount}€ for {item.category} on {item.date}
                    </span>
        </div>
    );

    let summary;
    if (total > 0) {
        summary = "Lisa schuldet Jakob " + total / 2 + "€"
    } else if (total < 0) {
        summary = "Jakob schuldet Lisa " + -total / 2 + "€"
    } else {
        summary = "Lisa und Jakob sind ausgeglichen"
    }

    return (<div className="container-fluid">
        <div className="mb-4">
            <h1 className="h3 mb-0 text-gray-800" style={{display: 'inline'}}>Money</h1>
        </div>
        <div className="row">
            <div className="col-lg-6">
                <InputGroup style={{width: "400px"}}>
                    <Form.Control
                        type="number"
                        placeholder={"Amount"}
                        value={text}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => {
                            setText(event.target.value)
                        }
                        }
                        autoFocus={true}
                    />
                    <Button onClick={(e) => {
                        submit();
                    }}>
                        Add bill
                    </Button>
                </InputGroup>
                <Form.Group
                    controlId="formBasicPayee"
                    onChange={(event) => setPayee(event.target.value)}
                >
                    <Form>
                        <Form.Check
                            type="radio"
                            label="Lisa hat bezahlt"
                            name="payee"
                            value="lisa"
                            id="lisapayed"
                        />
                        <Form.Check
                            type="radio"
                            label="Jakob hat bezahlt"
                            name="payee"
                            value="jakob"
                            id="jakobpayed"
                        />
                        <Form.Check
                            type="radio"
                            label="Edenred Einzahlung auf Karte"
                            name="payee"
                            value={"edenred"}
                            id="edenred"
                        />
                    </Form>
                </Form.Group>
                <Form.Group controlId="formBasicCategory">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="Select category"
                                 onChange={(event) => setCategory(event.target.value)}
                                 defaultValue={"food"}
                    >
                        <option value="food">Lebensmittel</option>
                        <option value="edenred">Edenred</option>
                        <option value="other">Anderes</option>
                    </Form.Select>
                </Form.Group>

                { /* Date picker with today selected by default */}
                <Form.Group controlId="formBasicDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" placeholder="Enter date"
                                  onChange={(event) => setDate(event.target.value)}
                                  value={date}
                    />
                </Form.Group>
            </div>
            <div className="col-lg-6">
                <h3>{summary}</h3>
                {listItems}
            </div>
        </div>
    </div>)
        ;
}

export default Money;
