import React from 'react';
import UpdatableCard from "./UpdatableCard";
import {IngredientBox} from "../IngredientBox";
import {InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {btnoptions} from "../ui/ButtonList";

function IngredientsCard({title, ingredients, showModal}) {
    const [text, setText] = React.useState("");

    if (!ingredients.isSuccess) {
        return <div>Loading...</div>
    }
    let list = Object.values(ingredients.data);
    list = list.filter((ingredient) => ingredient.name.toLowerCase().includes(text.toLowerCase()))
    if (list.length > 5) {
        list = list.slice(0, 5)
        title += " (showing first 5)"
    }
    let ingredientshtml = list.map((value, index) => <IngredientBox
        highlighted={false}
        key={"box-" + index}
        item={value}
        options={[btnoptions.EDIT, btnoptions.DELETE]}
        name={value["name"]}
        onEdit={(item) => {
            showModal(item)
        }}/>);
    return (<UpdatableCard title={title} empty={false}>
        <InputGroup style={{marginBottom: 5 + "px"}}>
            <Form.Control placeholder={"Search ingredients..."}
                          onChange={(event) => setText(event.target.value)}
            />
            <Button onClick={(e) => {
                e.preventDefault();
                showModal()
            }
            }>
                Add new ingredient
            </Button>
        </InputGroup>
        {ingredientshtml}
    </UpdatableCard>);
}

export default IngredientsCard;