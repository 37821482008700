import React from 'react';
import {authHeader} from "../../api/authHeader";
import TodoEntry from "../TodoEntry";
import {getMorningTime, isOverdue, isToday} from "../../helpers/dateHelper";
import {handleResponse} from "../../api/handleResponse";
import DatepickerCard from "./DatepickerCard";
import {getTodoText, sortTodos} from "../../helpers/helpers";
import styled from "styled-components";
import {updateItem} from "../../api/entityApiHandler";
import {btnoptions} from "../ui/ButtonList";
import {useMutation, useQuery, useQueryClient} from "react-query";


const LinkButton = styled.a`
    cursor: pointer;
`

function TodosCard(props) {
    const queryClient = useQueryClient()
    const fetchTodos = () => {
        let queryDict = {
            day: props.currentDate.unix() - 4 * 60 * 60 - 30 * 60,
        };

        if (isToday(props.currentDate)) {
            queryDict["show_overdue"] = true
        }

        const requestOptions = {
            method: 'POST', headers: authHeader({'Content-Type': 'application/json'}), body: JSON.stringify(queryDict)
        };

        return fetch(process.env.REACT_APP_API_URL + '/todos/query', requestOptions)
            .then(handleResponse).then(data => {
                data.sort(sortTodos)
                return data
            });
    }

    const todosQuery = useQuery({
        queryKey: ['todos', props.currentDate.format('YYYY-MM-DD')],
        queryFn: fetchTodos
    })

    const replanTodosMutation = useMutation({
        mutationFn: (todos) => {
            const promises = [];
            for (let todo of todos) {
                if (isOverdue(todo)) {
                    todo.planned_after = getMorningTime().unix();
                    promises.push(updateItem(todo));
                }
            }
            return Promise.all(promises);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['todos', 'today'])
        }
    })

    if (!todosQuery.isSuccess) {
        return <div>Loading...</div>
    }

    let todosHtml = todosQuery.data.map((item, index) => {
        let options
        if ("repetition" in item) {
            options = [btnoptions.CHECK_OFF_TODO];
        } else {
            options = [btnoptions.START_NOW, btnoptions.CHECK_OFF_TODO, btnoptions.UNPLAN, btnoptions.DELETE, btnoptions.EDIT];
        }
        return <TodoEntry showTodoModal={props.showTodoModal} key={index} item={item} options={options}
                          getText={getTodoText} currentDate={props.currentDate}/>;
    })

    let earnedString = ""
    let earned = 0, bountytotal = 0, bountytodo = 0
    for (let todo of todosQuery.data) {
        if (todo.bounty && todo.bounty !== 0) {
            if (todo.done) {
                earned += todo.bounty
            } else {
                bountytodo += todo.bounty
            }
            bountytotal += todo.bounty
        }
    }

    if (bountytotal !== 0) {
        earnedString = " (" + earned + "$/" + bountytotal + "$ earned, " + bountytodo + "$ to do)"
    }

    // check if any are overdue
    let overdue = false
    for (let todo of todosQuery.data) {
        if (isOverdue(todo)) {
            overdue = true
            break
        }
    }

    let replanBtn = null
    if (overdue) {
        replanBtn = <LinkButton onClick={() => replanTodosMutation.mutate(todosQuery.data)
        }>Replan all Todos to today</LinkButton>
    }

    return (<DatepickerCard title={"Your Todos" + earnedString} empty={todosHtml.length === 0}
                            currentDate={props.currentDate}
                            daysPerClick={1}
                            changeDate={(date) => {
                                props.changeDate(date)
                            }}>
        <ul style={{
            listStyle: "none", paddingInline: 0
        }}>
            <div style={{padding: "0 0 10px 4px"}}>
                {replanBtn}
            </div>
            {todosHtml}
        </ul>
    </DatepickerCard>);
}

export default TodosCard;