import React, {useState} from 'react';
import IngredientsCard from "../../cards/IngredientsCard";
import RecipesCard from "../../cards/RecipesCard";
import {getCurrentTime} from "../../../helpers/dateHelper";
import {addUpRecipeStats} from "../../modals/AddRecipeModal";
import {useQuery} from "react-query";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";

function FoodData(props) {
    const [date, setDate] = useState(getCurrentTime())
    const ingredients = useQuery({
        queryKey: ['food', 'ingredients'],
        queryFn: () => {
            return fetch(process.env.REACT_APP_API_URL + '/food/ingredients', {
                method: 'GET', headers: authHeader({'Content-Type': 'application/json'})
            }).then(handleResponse).then(data => {
                let ingredientDict = {};
                for (let ingredient of data) {
                    ingredientDict[ingredient["name"]] = ingredient;
                }
                return ingredientDict;
            })
        }
    })
    const recipes = useQuery({
        queryKey: 'recipes',
        queryFn: () => fetch(process.env.REACT_APP_API_URL + '/food/recipes', {
            method: 'GET', headers: authHeader({'Content-Type': 'application/json'})
        }).then(handleResponse).then(data => {
            let recipesDict = {};
            for (let recipe of data) {
                recipesDict[recipe["name"]] = recipe;
            }
            return recipesDict;
        })
    })


    if (ingredients.isSuccess && recipes.isSuccess) {
        for (let recipe of Object.values(recipes.data)) {
            recipe["stats"] = addUpRecipeStats(1, recipe["ingredients"], ingredients.data)
        }
    }

    return (
        <div className="container-fluid">
            <div className="mb-4">
                <h1 className="h3 mb-0 text-gray-800" style={{display: 'inline'}}>Food data</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <RecipesCard day={date} recipes={recipes} title={"Recipes"}
                                 showModal={(editData = null) => {
                                     props.showRecipeModal()
                                     if (editData) {
                                         props.setRecipeEditData(editData)
                                     }
                                 }}/>
                </div>
                <div className="col-lg-6">
                    <IngredientsCard ingredients={ingredients} title={"Ingredients"}
                                     showModal={(editData = null) => {
                                         props.showIngredientModal()
                                         if (editData) {
                                             props.setIngredientEditData({
                                                 name: "", serving_size_amount: "", serving_size_unit: "g", "stats": {
                                                     "energy-kcal_100g": "",
                                                     "carbohydrates_100g": "",
                                                     "fat_100g": "",
                                                     "proteins_100g": "",
                                                     "energy-kcal_serving": "",
                                                     "carbohydrates_serving": "",
                                                     "fat_serving": "",
                                                     "proteins_serving": "",
                                                 },
                                                 alternative_names: "",
                                                 ...editData
                                             })
                                         }
                                     }}/>
                </div>
            </div>
        </div>
    )
}

export default FoodData;