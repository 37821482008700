import React from 'react';
import {authHeader} from "../../api/authHeader";
import TodoEntry from "../TodoEntry";
import UpdatableCard from "./UpdatableCard";
import Omnibar from "../Omnibar";
import {handleResponse} from "../../api/handleResponse";
import {getTodoText} from "../../helpers/helpers";
import {submitListEntry} from "../../api/listApiHandler";
import {btnoptions, ButtonList} from "../ui/ButtonList";
import {useMutation, useQuery, useQueryClient} from "react-query";

function CheckListCard(props) {

    const fetchList = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader({'Content-Type': 'application/json'}),
        };

        return fetch(process.env.REACT_APP_API_URL + '/list/' + props.listid, requestOptions)
            .then(handleResponse)
            .then(data => {
                    data.sort(function (a, b) {
                        if (a.done === b.done) {
                            return 0
                        } else if (a.done) {
                            return 1
                        } else {
                            return -1
                        }
                    })

                    return data
                }
            );
    }

    const queryClient = useQueryClient()
    const list = useQuery(['list', props.listid], fetchList)
    const mutateList = useMutation({
        mutationFn: submitListEntry,
        onSuccess: () => {
            queryClient.invalidateQueries(['list', props.listid])
        }
    })

    if (!list.isSuccess) {
        return "Loading..."
    }

    let listHTML = list.data.map((item, index) =>
        <TodoEntry hiddenProps={(item) => ({listid: item.listid})} key={index} item={item}
                   options={[btnoptions.CHECK_OFF_LISTITEM, btnoptions.DELETE]} getText={getTodoText}/>
    );

    return (
        <UpdatableCard title={props.title} empty={false}
                       buttons={<ButtonList item={props.listid} options={[btnoptions.DELETE_LIST]}/>}>
            <ul style={{
                listStyle: "none",
                paddingInline: 0
            }}>
                {listHTML}
                {
                    props.editable ?
                        <Omnibar onSubmit={(entry) =>
                            mutateList.mutate({listid: props.listid, entry: entry})
                        }
                                 placeholder={"Add item ..."} resizeable={true}/> : ""
                }
            </ul>
        </UpdatableCard>
    );
}

export default CheckListCard;