import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {buyItem} from "../../../api/creditsApiHandler";

function CreditsShop() {
    return (<div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Credits shop</h1>
        </div>

        <Container style={{border: 1 + "px solid"}}>
            <Row>
                <Col>
                    <b>1 Stunde extra Spielen abends</b>
                </Col>
                <Col>
                    <b>Cost 500</b>
                </Col>
                <Col>
                    <button className="btn btn-primary" onClick={() => buyItem("extra_gaming_evening")}>Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>1 Stunde extra Spielen Zeit egal</b>
                </Col>
                <Col>
                    <b>Cost 750</b>
                </Col>
                <Col>
                    <button className="btn btn-primary" onClick={() => buyItem("extra_gaming_anytime")}>Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>100 extra kalorien</b>
                </Col>
                <Col>
                    <b>Cost 200</b>
                </Col>
                <Col>
                    <button className="btn btn-primary" onClick={() => buyItem("100_kalories")}>Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Streak challenge 3 Tage</b>
                </Col>
                <Col>
                    <b>Cost 100</b>
                </Col>
                <Col>
                    <button className="btn btn-primary">Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Streak challenge Woche</b>
                </Col>
                <Col>
                    <b>Cost 500</b>
                </Col>
                <Col>
                    <button className="btn btn-primary">Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Streak challenge Monat</b>
                </Col>
                <Col>
                    <b>Cost 1000</b>
                </Col>
                <Col>
                    <button className="btn btn-primary">Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Sport challenge 3 Tage</b>
                </Col>
                <Col>
                    <b>Cost 100</b>
                </Col>
                <Col>
                    <button className="btn btn-primary">Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Sport challenge Woche</b>
                </Col>
                <Col>
                    <b>Cost 500</b>
                </Col>
                <Col>
                    <button className="btn btn-primary">Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Sport challenge Monat</b>
                </Col>
                <Col>
                    <b>Cost 1000</b>
                </Col>
                <Col>
                    <button className="btn btn-primary">Buy</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>...</b>
                </Col>
                <Col>
                    <b>...</b>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    </div>);
}

export default CreditsShop;