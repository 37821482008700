import {parseAmount} from "./RecipeParser";

export function parseFoodPlanItem(item, words, ingredients, recipes) {
    let amount = parseAmount(words[words.length - 1])
    let dict;
    if (amount) {
        dict = {
            name: words.slice(0, words.length - 1).join(" "),
            amount: amount["amount"],
            unit: amount["unit"]
        }
    } else {
        dict = {
            name: words.join(" ")
        }
    }

    if (ingredients[dict["name"]]) {
        dict["food_type"] = "ingredient"
    } else if (recipes[dict["name"]]) {
        dict["food_type"] = "recipe"
        if (dict["unit"] && dict["unit"] !== "x") {
            throw Error("Amount must be given in servings (e.g. \"2x\")")
        }
    } else {
        throw Error("Could not find ingredient or recipe. Quick-adding is not yet supported.")
    }

    return dict
}

export function unparseFoodPlanItem(dict) {
    return dict["name"] + " " + dict["amount"] + dict["unit"]
}