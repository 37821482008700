import React from 'react';
import {DroppableWeekday} from "./DroppableWeekday";
import styled from "styled-components";
import moment from "moment";

const Container = styled.div`
  display: flex;
  background: white;
  align-content: center;
  margin: 0 0 8px;
`

function PlannerCard(props) {
        return <Container>
            {[0, 1, 2, 3, 4, 5, 6].map((id, index) => {
                let date = moment.utc(props.date).add(id, "days")

                return <DroppableWeekday key={id} todos={props.todos[id]} index={index.toString()}
                                         title={date.format("dd, DD.MM.")}/>
            })}
        </Container>;
}

export default PlannerCard