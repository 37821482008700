import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {generateTodo} from "../../randomWord";
import HoverableInfoIcon from "../../helpers/HoverableInfoIcon";
import {Col, Row} from "react-bootstrap";
import {getCurrentTime, mongoToMoment} from "../../helpers/dateHelper";

export function AddGoalModal(props) {
    const close = () => {
        clear()
        props.onClose()
    }

    const clear = () => {
        setName("")
        setMotivation("")
        setPlannedFinishTime(getCurrentTime().add(3, "months").format("YYYY-MM-DD"))
        setStartTime(getCurrentTime().format("YYYY-MM-DD"))
        setFinished(false)
        setMeasurementType(0)
        setShownGoalId(-1)
    }

    const [name, setName] = React.useState("")
    const [motivation, setMotivation] = React.useState("")
    const [planned_finish_time, setPlannedFinishTime] = React.useState(getCurrentTime().add(3, "months").format("YYYY-MM-DD"))
    const [start_time, setStartTime] = React.useState(getCurrentTime().format("YYYY-MM-DD"))
    const [finished, setFinished] = React.useState(false)
    const [measurement_type, setMeasurementType] = React.useState(0)
    const [shownGoalId, setShownGoalId] = React.useState(-1)

    if (props.goal && shownGoalId !== props.goal.id) {
        setName(props.goal.name)
        setMotivation(props.goal.motivation)
        setPlannedFinishTime(mongoToMoment(props.goal.planned_finish_time).format("YYYY-MM-DD"))
        setStartTime(mongoToMoment(props.goal.start_time).format("YYYY-MM-DD"))
        setFinished(props.goal.finished ? props.goal.finished : false)
        setMeasurementType(props.goal.measurement_type)
        console.assert(props.goal.type === "goal")
        setShownGoalId(props.goal.id)
    }

    return <Modal size="xl" show={props.show}>
        {/* TODO close button <Modal.Header closeButton>*/}
        <Modal.Header>
            <Modal.Title>New Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label className="h6">Goal type</Form.Label><br/>
                    <Form.Label className="text-muted">
                        It is important to know how you will measure your progress. This will help you to stay on
                        track and to know when you have reached your goal.
                        The following types are available:
                        <ul>
                            <li key={1}><b>Self-tracked:</b> The goal will not be tracked by TakeC.</li>
                            <li key={2}><b>Habitual boolean:</b> You want to build a habit which is either done or not
                                done
                                in a time frame. Example: "I want to stop smoking.". If you have a habitual goal,
                                you will need to check in with this app to track your progress.
                            </li>
                            <li key={3}><b>Habitual quantitative:</b> <i>(not implemented) </i>You want to build a habit
                                which is quantifiable. Example: "I want to drink at least 5 glasses of water every
                                day until it becomes a habit."
                            </li>
                            <li key={4}><b>Milestone-based:</b> <i>(not implemented) </i>You want to reach a goal which
                                has
                                multiple sub-goals or milestones. Example: "I want to build a
                                house." <br/> Milestones: Design -> Prepare construction site -> Lay foundation ->
                                ...
                            </li>
                            <li key={5}><b>Quantitative total:</b> <i>(not implemented) </i>You want to reach a goal
                                which
                                can be quantified. Example: "I want to save up 2000€ for financial security."
                            </li>
                            <li key={6}><b>Journal-based qualitative:</b> <i>(not implemented) </i>You want to reach a
                                goal
                                which is not quantifiable, but is assessed through self-reflection or feedback from
                                others. Example: "I want to become more open to criticism."
                            </li>
                        </ul>
                        {/*Remote means that someone else will check or measure your progress.*/}
                    </Form.Label>
                    <Form.Select value={measurement_type} onChange={(e) => {
                        setMeasurementType(e.target.value)
                    }}>
                        <option value={-1}>Self-tracked</option>
                        <option value={0}>Habitual boolean</option>
                        <option disabled value={1} title={"Not implemented yet"}>Habitual quantitative</option>
                        <option disabled value={2} title={"Not implemented yet"}>Milestone-based</option>
                        <option disabled value={3} title={"Not implemented yet"}>Quantitative total</option>
                        <option disabled value={4} title={"Not implemented yet"}>Journal-based qualitative</option>
                        {/*<option disabled value={5} title={"Not implemented yet"}>Habitual boolean (remote checked)*/}
                        {/*</option>*/}
                        {/*<option disabled value={6} title={"Not implemented yet"}>Habitual quantitative (remote measured)*/}
                        {/*</option>*/}
                        {/*<option disabled value={7} title={"Not implemented yet"}>Quantitative total (remote measured)*/}
                        {/*</option>*/}
                        {/*<option disabled value={4} title={"Not implemented yet"}>Journal-based qualitative (remote assessed)*/}
                        {/*</option>*/}
                    </Form.Select>
                </Form.Group>
                <Form.Label className="h6">Goal Statement</Form.Label>
                <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                    <HoverableInfoIcon
                        text="Specify what you want to reach and until which date. It is important for your statement to be specific and time-based."/>
                </Form.Label><br/>
                <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                    Specify what you want to reach and until which date. It is important for your statement to be
                    specific and time-based.
                </Form.Label>
                <Form.Group className="mb-3">
                    <Row>
                        <Col sm={1} style={{paddingTop: "0.4em"}}>
                            I want to
                        </Col>
                        <Col sm={8}>
                            <Form.Control placeholder={generateTodo().toLowerCase()} autoFocus
                                          value={name}
                                          onChange={(event) => {
                                              setName(event.target.value)
                                          }}/>
                        </Col>
                        {
                            measurement_type !== -1 ? [
                                    <Col key={1} sm={1} style={{paddingTop: "0.4em"}}>
                                        until
                                    </Col>,
                                    <Col key={2} sm={2}>
                                        <Form.Control type={"date"} value={planned_finish_time}
                                                      onChange={(e) => {
                                                          setPlannedFinishTime(e.target.value)
                                                      }}/>
                                    </Col>,
                                    <Row key={3}>
                                        <Col sm={1} style={{paddingTop: "0.4em"}}>
                                            starting
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Control type={"date"} value={start_time} onChange={(e) => {
                                                setStartTime(e.target.value)
                                            }}/>
                                        </Col>
                                    </Row>
                                ]
                                : null
                        }
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="h6">Why do you want to reach this goal?</Form.Label>
                    <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                        <span style={{paddingRight: "0.5em"}}>(optional)</span><HoverableInfoIcon
                        text="Your goal must be relevant. It is important that you are motivated by this goal and convinced that it is worth doing."/>
                    </Form.Label>
                    <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                        Your goal must be relevant. It is important that you are motivated by this goal and
                        convinced that it is worth doing. Try to convince future you to keep on pursuing this goal.
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} value={motivation} onChange={(e) => {
                        setMotivation(e.target.value)
                    }}/>
                </Form.Group>
                {/*
                    TODO: Interval for Habitual boolean
                    <Form.Group className="mb-3">
                        <Form.Label>Bounty</Form.Label>
                        <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                            <HoverableInfoIcon
                                text="The bounty which should be awarded when finishing the task."/>
                        </Form.Label>
                        <Form.Control value={bounty} onChange={(e) => {
                            setBounty(e.target.value)
                        }}/>
                    </Form.Group>*/}
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={close}>
                Close
            </Button>
            <Button variant="primary" onClick={() => {
                props.onSave(
                    {
                        name: name,
                        motivation: motivation,
                        planned_finish_time: planned_finish_time,
                        start_time: start_time,
                        finished: finished,
                        measurement_type: measurement_type
                    })
                close()
            }}>
                Save Goal
            </Button>
        </Modal.Footer>
    </Modal>;
}