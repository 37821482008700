import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authHeader} from "../../api/authHeader";
import {handleResponse} from "../../api/handleResponse";
import {checkIn} from "../../api/todoApiHandler";
import {DatePickerWithButtons} from "../DatepickerWithButtons";
import {getCurrentTime} from "../../helpers/dateHelper";

export function CheckInModal(props) {
    const queryClient = useQueryClient()
    const [checkboxValues, setCheckboxValues] = useState({});
    const [journal, setJournal] = useState("");
    const [finished, setFinished] = useState(false);
    const [currentDate, setCurrentDate] = useState(getCurrentTime());

    const checkInData = useQuery({
        queryKey: ['checkInData', currentDate.format('YYYY-MM-DD')],
        queryFn: () => {
            return fetch(process.env.REACT_APP_API_URL + '/checkin?date=' + currentDate.format('YYYY-MM-DD'), {
                method: 'GET', headers: authHeader()
            }).then(handleResponse).then(data => {
                const newValues = {}
                data.goal_progress.forEach((goal) => {
                    newValues[goal["name"]] = goal["done"]
                })
                setCheckboxValues(newValues)
                setJournal(data.journal)
                setFinished(data.finished)
                return data
            })
        }
    })
    const mutate = useMutation({
        mutationFn: (data) => checkIn(data, currentDate),
        onSuccess: () => {
            queryClient.invalidateQueries('checkInData')
            queryClient.invalidateQueries('projects')
        }
    })

    const onSubmit = () => {
        let newGoals = []
        for (let goal in checkboxValues) {
            newGoals.push({
                name: goal,
                done: checkboxValues[goal],
            })
        }
        mutate.mutate({
            journal: journal,
            goal_progress: newGoals,
            finished: finished
        })
        setCurrentDate(getCurrentTime())
        props.onClose()
    }

    return <Modal size="xl" show={props.show}>
        {/* TODO close button <Modal.Header closeButton>*/}
        <Modal.Header>
            <Modal.Title>Journal</Modal.Title>
            <DatePickerWithButtons changeDate={(date) => {
                setCurrentDate(date.clone())
            }} currentDate={currentDate}/>
        </Modal.Header>
        {
            checkInData.isSuccess ? <Modal.Body>
                    <Form.Label className="h5">
                        How was your day?
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="How was your day?"
                                  value={journal}
                                  onChange={(event) => {
                                      setJournal(event.target.value)
                                  }}
                    />
                    <hr/>
                    <Form.Label className="h5">
                        Goals
                    </Form.Label>
                    <Form>
                        {checkInData.data.goal_progress.map((goal, index) => {
                            return <Form.Group controlId="formBasicCheckbox" key={index}>
                                <Form.Label
                                            key={index}>
                                    {goal["name"]}:
                                </Form.Label>
                                <Form.Check inline>
                                    <Form.Check.Input type="radio" checked={checkboxValues[goal["name"]] === true}
                                                      id={"yes" + index}
                                                      onClick={(event) => {
                                                          let newValues = {...checkboxValues}
                                                          if ((goal["name"] in newValues) && newValues[goal["name"]] === true) {
                                                              delete newValues[goal["name"]]
                                                              setCheckboxValues(newValues)
                                                          }
                                                      }}
                                                      onChange={(event) => {
                                                          let newValues = {...checkboxValues}
                                                          newValues[goal["name"]] = true
                                                          setCheckboxValues(newValues)
                                                      }}/>
                                    <Form.Check.Label>Yes</Form.Check.Label>
                                </Form.Check>
                                <Form.Check inline>
                                    <Form.Check.Input type="radio"
                                                      id={"no" + index}
                                                      checked={checkboxValues[goal["name"]] === false}
                                                      onChange={(event) => {
                                                          let newValues = {...checkboxValues}
                                                          newValues[goal["name"]] = false
                                                          setCheckboxValues(newValues)
                                                      }}
                                                      onClick={(event) => {
                                                          let newValues = {...checkboxValues}
                                                          if ((goal["name"] in newValues) && newValues[goal["name"]] === false) {
                                                              delete newValues[goal["name"]]
                                                              setCheckboxValues(newValues)
                                                          }
                                                      }}/>
                                    <Form.Check.Label>No</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        })}
                        <hr/>
                        <Form.Label className="h5">
                            Finish Journal
                        </Form.Label>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label={"Close journal for today (skip reminders)"}
                                        checked={finished}
                                        id={"finished"}
                                        onChange={() => {
                                            setFinished(!finished)
                                        }}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                : <Modal.Body>
                    {"Error: " + checkInData.status}
                </Modal.Body>
        }
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onClose}>
                Close
            </Button>
            <Button variant="primary" onClick={onSubmit}>
                Save
            </Button>
        </Modal.Footer>
    </Modal>;
}