import React from 'react';
import TodoEntry from "../../TodoEntry";
import UpdatableCard from "../UpdatableCard";
import {submitFoodPlanItem} from "../../../api/foodApiHandler";
import Omnibar from "../../Omnibar";
import {getFoodplanItemText} from "../../../helpers/helpers";
import {parseFoodPlanItem} from "../../../parser/FoodplanItemParser";
import {calculateItemStats} from "../../../helpers/FoodHelper";
import {btnoptions} from "../../ui/ButtonList";
import {useMutation, useQueryClient} from "react-query";

export function getRecommendations(ingredients, recipes) {
    let recommendations = [];

    if (ingredients && recipes) {
        for (let recipe of Object.values(recipes)) {
            recommendations.push(recipe)
        }
        for (let ingredient of Object.values(ingredients)) {
            recommendations.push(ingredient)
        }
    }
    return recommendations;
}

function Foodplan(props) {
    let planhtml = "";

    const queryClient = useQueryClient()
    const submitFoodPlanMutation = useMutation({
            mutationFn: ({entry, item}) =>
                submitFoodPlanItem(props.for, parseFoodPlanItem(item, entry.split(" "), props.ingredients, props.recipes), props.date),
            onSuccess: () => {
                queryClient.invalidateQueries(['food', 'planned_food'])
            }
        }
    )

    function isInvalid(item, ingredients, recipes) {
        let itemStats = calculateItemStats(item, ingredients, recipes);
        return !itemStats || !itemStats["energy-kcal"] || !itemStats["carbohydrates"] || !itemStats["fat"] || !itemStats["proteins"];
    }

    if (props.plan) {
        planhtml = props.plan.map((item, index) => <TodoEntry
            invalid={isInvalid(item, props.ingredients, props.recipes)} key={index} item={item}
            options={[//btnoptions.PORTION_UP, btnoptions.PORTION_DOWN,
                btnoptions.DELETE]} getText={getFoodplanItemText}/>);
    }

    let recommendations = getRecommendations(props.ingredients, props.recipes);

    return (<UpdatableCard title={"Plan for " + props.for} empty={false}>
        <ul style={{
            listStyle: "none", paddingInline: 0
        }}>
            {planhtml}
            {<Omnibar
                onSubmit={(entry, item) => submitFoodPlanMutation.mutate({entry, item})}
                placeholder={"Plan food ..."} resizeable={true} recommendations={recommendations}/>}
        </ul>
    </UpdatableCard>);
}

export default Foodplan;