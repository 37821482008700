import React from 'react';
import CheckListCard from "./CheckListCard";

function OwnListsCard(props) {
    return props.lists.map((item, index) =>
        <div className="container-fluid">

            <div key={index}>
                <CheckListCard title={item.name} editable={true} listid={item["_id"]["$oid"]}
                               deletable={true} _id={item["_id"]["$oid"]}/>
            </div>
        </div>
    );
}

export default OwnListsCard;