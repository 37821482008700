import React from 'react';


function UpdatableCard(props) {
    let titleText = props.title + (props.empty ? " (Empty)" : "");

    let title;
    if ("title" in props) {
        title = <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">{props.titleLink ? <a href="." onClick={(e) => {
                e.preventDefault();
                props.titleLink()
            }}>{titleText}</a> : titleText}</h6>
            {props.buttons}
        </div>;
    } else {
        title = "";
    }

    let content;
    if (!props.empty) {
        content = <div className="card-body">
            {props.children}
        </div>;
    } else {
        content = null;
    }

    return (
        <div className="card shadow mb-4">
            {title}
            {content}
        </div>
    );
}

export default UpdatableCard;