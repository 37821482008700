import React from 'react';
import UpdatableCard from "./UpdatableCard";
import {authHeader} from "../../api/authHeader";
import {handleResponse} from "../../api/handleResponse";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';

import ProgressBar from 'react-bootstrap/ProgressBar';
import {HABITUAL_BOOLEAN} from "../pages/app-pages/Goals";
import {useQuery} from "react-query";
import {btnoptions, ButtonList} from "../ui/ButtonList";

ChartJS.register(ArcElement, Tooltip, Legend);
const default_data = {
    labels: ["Done", "To-Do"],
    datasets: [{
        data: [80, 20],
        backgroundColor: [
            '#1cc88a',
            '#4e73df',
        ],
        hoverBackgroundColor: [
            '#17a673',
            '#2e59d9',
        ],
        hoverBorderColor: "rgba(234, 236, 244, 1)",
    }],
};

const options = {
    maintainAspectRatio: false,
    tooltips: {
        backgroundColor: "rgb(255,255,255)",
        bodyFontColor: "#858796",
        borderColor: '#dddfeb',
        borderWidth: 1,
        xPadding: 15,
        yPadding: 15,
        displayColors: false,
        caretPadding: 10,
    },
    legend: {
        display: false
    },
    cutoutPercentage: 80,
};

function ProjectCard(props) {
    const todos = useQuery({
            queryKey:
                ['todos'],
            initialData:
                [],
            queryFn:
                () => {
                    const requestOptions = {
                        method: 'POST',
                        headers: authHeader({'Content-Type': 'application/json'}),
                        body: JSON.stringify({
                            "subproject_of": props.project.id,
                        })
                    };

                    return fetch(process.env.REACT_APP_API_URL + '/todos/query', requestOptions)
                        .then(handleResponse)
                        .then(data => {
                                return data;
                            }
                        );
                }
        }
    )

    if (!todos.isSuccess) {
        return "Loading..."
    }

    const todo_data = [];
    todo_data.push(todos.data.filter(todo => "done" in todo && todo.done).length)
    todo_data.push(todos.data.filter(todo => !("done" in todo) || !todo.done).length)

    const chart_data = JSON.parse(JSON.stringify(default_data))
    chart_data["datasets"][0]["data"] = todo_data;
    if (props.project.measurement_type === HABITUAL_BOOLEAN) {
        return (
            <UpdatableCard title={props.project.name == null ? props.project.id : props.project.name}
                           empty={false} buttons={<ButtonList showGoalModal={props.showGoalModal} item={props.project} options={[btnoptions.EDIT_GOAL, btnoptions.DELETE_GOAL]}/>}>
                Current streak: <h4>{props.project["current_streak"]} days!</h4>
                <ProgressBar label={props.project["current_streak"] + `/100`}
                             now={props.project["current_streak"] / 100} max={1}/>
            </UpdatableCard>
        );
    } else {
        return (
            <UpdatableCard title={props.project.name == null ? props.project.id : props.project.name}
                           empty={false}>
                <ul style={{
                    listStyle: "none",
                    paddingInline: 0
                }}>
                    <Doughnut data={chart_data} type={"doughnut"} options={options}/>
                    {props.project.description}
                </ul>
                <ProgressBar now={60}/>;
            </UpdatableCard>
        );
    }
}

export default ProjectCard;