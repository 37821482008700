import {dictEmpty} from "../helpers/helpers";

const parsers = [parsePart]

export function parseRecipe(words, recipes) {
    const recipeDict = {}
    let name = []

    let parsed_total = 0
    let parsed
    recipeDict["ingredients"] = []
    words.forEach(word => {
        parsed = 0
        parsers.forEach(parser => {
            if (parser(recipeDict, word)) {
                parsed++
                parsed_total++
            }
        })

        if (parsed > 1) {
            throw new Error("Ambiguous option found. No recipe was submitted.")
        }

        if (parsed === 0) {
            if (parsed_total > 0) {
                throw new Error("Commands are only allowed at the end of the string. No recipe was submitted.")
            }
            name.push(word)
        }
    })

    recipeDict.name = name.join(" ")

    if (recipes[recipeDict.name]) {
        throw new Error("Recipe with that name already exists!")
    }

    return recipeDict
}

export function parseAmount(text) {
    if (text.endsWith("g")) {
        let split = text.split("g")
        if (split.length !== 2) {
            return false
        }

        let amount = parseFloat(split[0])
        if (isNaN(amount)) {
            return false
        }

        return {
            amount: amount,
            unit: "g"
        }
    }

    if (text.endsWith("x")) {
        let split = text.split("x")
        if (split.length !== 2) {
            return false
        }

        let amount = parseFloat(split[0])
        if (isNaN(amount)) {
            return false
        }

        return {
            amount: amount,
            unit: "x"
        }
    }

    return undefined;
}

export function parseRecipePart(words) {
    let name = []
    let partDict = {}

    words.forEach(word => {
        let parsedAmount = parseAmount(word)
        if (parsedAmount) {
            if (dictEmpty(partDict)) {
                partDict = parsedAmount
                return
            } else {
                throw new Error("Please specify only one amount")
            }
        }

        if (!dictEmpty(partDict)) {
            throw new Error("The amount must be specified at the end of ingredient")
        } else {
            name.push(word)
        }
    })

    partDict.name = name.join(" ")
    if (!partDict.amount) {
        partDict.amount = 1
    }
    if (!partDict.unit) {
        partDict.unit = "x"
    }

    return partDict
}

export function parsePart(dict, text) {
    if (text.startsWith("+")) {
        let splittext = text.split("+")
        splittext = splittext[1].split(",")

        if (splittext.length !== 2) {
            return false
        }

        let item = parseAmount(splittext[1])
        if (!item) {
            return false;
        }

        let ingredient = {
            name: splittext[0],
            amount: item["amount"],
            unit: item["unit"]
        }

        dict["ingredients"].push(ingredient)
        return true
    }
}

