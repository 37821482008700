import {authHeader} from "./authHeader";
import {handleResponse} from "./handleResponse";

export function fetchCredits() {
    const requestOptions = {
        method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/credits', requestOptions)
        .then(handleResponse)
}

export function buyItem(key) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify({key: key})
    };

    fetch(process.env.REACT_APP_API_URL + '/credits', requestOptions)
        .then(handleResponse);
}