import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const ResultBox = styled.div`
  ${({highlighted}) => highlighted ? "background: lightgray" : "background: white"};
  width: 280px;
  border: lightgray 1px solid;
`

export class SmallIngredientBox extends Component {
    errorWrap = (tooltip, toCall) => {
        try {
            toCall();
        } catch (e) {
            tooltip.setAttribute("data-tip", e.toString())
            ReactTooltip.show(tooltip)
        }
    }

    render() {
        if (!this.props.item.stats) {
            return <ResultBox></ResultBox>
        }

        let energy_serving = "n/a";
        let energy_100g = "n/a";
        if ("energy-kcal" in this.props.item.stats) {
            energy_serving = this.props.item.stats["energy-kcal"].toFixed(0) + "kcal";
        }
        if ("energy-kcal_100g" in this.props.item.stats) {
            energy_100g = this.props.item.stats["energy-kcal_100g"].toFixed(0) + "kcal";
        }
        if ("energy-kcal_serving" in this.props.item.stats) {
            energy_serving = this.props.item.stats["energy-kcal_serving"].toFixed(0) + "kcal";
        }

        let fat_serving = "n/a";
        let fat_100g = "n/a";
        if ("fat" in this.props.item.stats) {
            fat_serving = this.props.item.stats["fat"].toFixed(0) + "f";
        }
        if ("fat_100g" in this.props.item.stats) {
            fat_100g = this.props.item.stats["fat_100g"].toFixed(0) + "f";
        }
        if ("fat_serving" in this.props.item.stats) {
            fat_serving = this.props.item.stats["fat_serving"].toFixed(0) + "f";
        }

        let carbohydrates_serving = "n/a";
        let carbohydrates_100g = "n/a";
        if ("carbohydrates" in this.props.item.stats) {
            carbohydrates_serving = this.props.item.stats["carbohydrates"].toFixed(0) + "c";
        }
        if ("carbohydrates_100g" in this.props.item.stats) {
            carbohydrates_100g = this.props.item.stats["carbohydrates_100g"].toFixed(0) + "c";
        }
        if ("carbohydrates_serving" in this.props.item.stats) {
            carbohydrates_serving = this.props.item.stats["carbohydrates_serving"].toFixed(0) + "c";
        }

        let proteins_serving = "n/a";
        let proteins_100g = "n/a";
        if ("proteins" in this.props.item.stats) {
            proteins_serving = this.props.item.stats["proteins"].toFixed(0) + "p";
        }
        if ("proteins_100g" in this.props.item.stats) {
            proteins_100g = this.props.item.stats["proteins_100g"].toFixed(0) + "p";
        }
        if ("proteins_serving" in this.props.item.stats) {
            proteins_serving = this.props.item.stats["proteins_serving"].toFixed(0) + "p";
        }

        return <ResultBox highlighted={this.props.highlighted}>
            <Container style={{padding: 0}}>
                <Row>
                    <Col sm={16}>
                        <b>{this.props.item.name}</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {energy_100g}
                    </Col>
                    <Col>
                        {carbohydrates_100g}
                    </Col>
                    <Col>
                        {fat_100g}
                    </Col>
                    <Col>
                        {proteins_100g}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {energy_serving}
                    </Col>
                    <Col>
                        {carbohydrates_serving}
                    </Col>
                    <Col>
                        {fat_serving}
                    </Col>
                    <Col>
                        {proteins_serving}
                    </Col>
                </Row>
            </Container>
        </ResultBox>
    }
}

SmallIngredientBox.propTypes = {
    highlighted: PropTypes.bool,
    item: PropTypes.object,
    kcal: PropTypes.number,
    carbs: PropTypes.number,
    fat: PropTypes.number,
    proteins: PropTypes.number
};