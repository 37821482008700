import {authenticationService} from "../services/authentication.service";

export function authHeader(headers={}) {
    const currentUser = authenticationService.currentUserValue;

    if (currentUser && currentUser.token) {
        headers["Authorization"] = `Bearer ${currentUser.token}`
    }

    return headers;
}