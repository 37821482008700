import React from 'react';
import CheckListCard from "../../cards/CheckListCard";

function ShoppingList() {
    return (
        <div className="container-fluid">

            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Shopping List</h1>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <CheckListCard title={"Shopping List"} listid={"shopping"} editable={true}/>
                </div>
                <div className="col-lg-6">
                    <CheckListCard title={"Other List"} listid={"other"} editable={true}/>
                </div>
            </div>
            <div className={"row"}>
                <div className="col-lg-6">
                    <CheckListCard title={"Recommendation"} listid={"reborn"} editable={false}/>
                </div>
            </div>

        </div>
    );
}

export default ShoppingList;