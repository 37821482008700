import {useQuery} from "react-query";
import {getCheckins} from "../../../api/todoApiHandler";
import {useMemo, useState} from "react";

export function Statistics() {
    const checkinDataQuery = useQuery('checkins', getCheckins)

    /**
     * {
     *   "_id": {
     *     "$oid": "65b3f4a3d684a9df2c4b7078"
     *   },
     *   "date": "2024-01-26",
     *   "owner": "64bd6ca82d94172d86bf7a4a",
     *   "finished": true,
     *   "goal_progress": [
     *     {
     *       "name": "test the goal",
     *       "id": "{'$oid': '65b3ed0dfd300b8579719225'}",
     *       "done": true
     *     }
     *   ],
     *   "journal": "Good day today!"
     * }
     */

        // Show statistics based on checkin data
        // Show edit field with number of days
        // Then, for each goal, show how many times this goal has been set to "true" in that time range with percentage and number of times

    const [daysInPast, setDaysInPast] = useState(7)
    const goalStats = useMemo(() => {
        let goalStats = {}
        goalStats["checkinTotal"] = 0
        if (checkinDataQuery.isSuccess) {
            const sortedCheckins = checkinDataQuery.data.sort((a, b) => new Date(b.date) - new Date(a.date))
            for (let checkin of sortedCheckins) {
                if (new Date(checkin.date) < new Date(new Date().getTime() - daysInPast * 24 * 60 * 60 * 1000)) {
                    break
                }

                goalStats["checkinTotal"]++
                for (let goal of checkin.goal_progress) {
                    if (!(goal.name in goalStats)) {
                        goalStats[goal.name] = {
                            name: goal.name,
                            done: 0,
                            not_done: 0,
                            total: 0
                        }
                    }
                    goalStats[goal.name].total++

                    // distinguish between done = False and done = undefined
                    if (goal.done) {
                        goalStats[goal.name].done++
                    } else if (goal.done === false) {
                        goalStats[goal.name].not_done++
                    } else {
                        console.error("Goal done is undefined")
                    }
                }
            }
        }
        return goalStats
    }, [checkinDataQuery.data, checkinDataQuery.isSuccess, daysInPast])

    const checkinTotal = goalStats["checkinTotal"]
    delete goalStats["checkinTotal"]

    return <div>
        <h1>Statistics</h1>
        <div>
            <label>Days in past:</label>
            <input type="number" min={1} value={daysInPast} onChange={(e) => setDaysInPast(e.target.value)}/>
        </div>
        <h4>Goal statistics</h4>
        Checked in for: {checkinTotal}/{daysInPast} days since {new Date(new Date().getTime() - daysInPast * 24 * 60 * 60 * 1000).toDateString()}
        <table style={{width: "100%", border: "1px solid black"}}>
            <thead>
            <tr>
                <th>Goal</th>
                <th>Done</th>
                <th>Not Done</th>
                <th>unknown</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(goalStats).map((goal, index) => {
                return <tr key={index}>
                    <td>{goal.name}</td>
                    <td>{(goal.done / daysInPast * 100).toFixed(2)}%</td>
                    <td>{(goal.not_done / daysInPast * 100).toFixed(2)}%</td>
                    <td>{((goal.total - goal.done - goal.not_done) / daysInPast * 100).toFixed(2)}%</td>
                    <td>{daysInPast}</td>
                </tr>
            })}
            </tbody>
        </table>
    </div>
}