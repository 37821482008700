import {authHeader} from "./authHeader";
import {handleResponse} from "./handleResponse";

export function repairTodo(item) {
    if (!item["repair"]) {
        alert("Cannot repair item, no repair instruction.")
        return
    }

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(item["repair"]),
    };

    return fetch(process.env.REACT_APP_API_URL + '/ent/' + item._id["$oid"], requestOptions)
        .then(handleResponse)
}
