import {authHeader} from "./authHeader";
import {handleResponse} from "./handleResponse";

export function fetchMoney() {
    const requestOptions = {
        method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/money', requestOptions)
        .then(handleResponse)
}

export function submitMoneyItem({amount, payee, date, category}) {
    const requestOptions = {
        method: 'POST', headers: authHeader({'Content-Type': 'application/json'}), body: JSON.stringify({
            "amount": amount,
            "payee": payee,
            "date": date,
            "category": category
        }),
    };

    return fetch(process.env.REACT_APP_API_URL + '/money', requestOptions)
        .then(handleResponse)
}
