import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import {InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import CheckListCard from "../../cards/CheckListCard";
import OwnListsCard from "../../cards/OwnListsCard";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";
import ReactTooltip from "react-tooltip";
import {useMutation, useQuery, useQueryClient} from "react-query";


const WishlistBox = styled.div`
    padding-left: 10px;
`

// sends POST request to backend for storing a new wishlist in the database
const submitWishList = (friend) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify({name: friend, type: 'wishlist'})
    };

    fetch(process.env.REACT_APP_API_URL + '/lists', requestOptions)
        .then(handleResponse)
}

/*
	Code snippet written by Lisa Falck from "Take Control" - web-app

	Component for creating wishlists

*/
function WishLists(props) {
    const [text, setText] = useState("")
    const queryClient = useQueryClient();

    const wishlist = useMutation({
        mutationFn: submitWishList,
        onSuccess: () => {
            queryClient.invalidateQueries('lists')
        }
    })


    // enables pressing enter in addition to pressing the button
    const handleKeyDown = (e) => {
        ReactTooltip.hide(props.tooltip)
        if (e.key === 'Enter' && text !== "") {
            wishlist.mutate(text);
            setText("")
        }
    }

    const wishlists = useQuery({
        queryKey: 'lists',
        initialData: [],
        queryFn: () => {
            const requestOptions = {
                method: 'GET',
                headers: authHeader({'Content-Type': 'application/json'})
            };
            return fetch(process.env.REACT_APP_API_URL + '/lists?type=wishlist', requestOptions)
                .then(handleResponse).then((data) => {
                    return data
                });
        }
    });

    return (
        <WishlistBox>
            <div style={{paddingLeft: '10px'}}
                 className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Wish Lists</h1>
            </div>
            {/* combines input textfield with a button to create a new wishlist */}
            <InputGroup style={{
                padding: 0,
                width: 500 + "px",
                boxShadow: '5px 5px 5px 2px #EAEDF2',
                borderRadius: '0.375rem'
            }}>

                <Form.Control style={{backgroundColor: '#FFFFFF'}}
                              placeholder={"Add a friend..."}
                              onChange={(event) => setText(event.target.value)}
                              onKeyDown={handleKeyDown} value={text}/>
                <Button onClick={(e) => {
                    e.preventDefault();
                    wishlist.mutate(text)
                }}>
                    +
                </Button>

            </InputGroup>
            {/* displays personal wishlist */}
            <div className="row" style={{marginTop: '15px'}}>
                <div className="col-lg-6" style={{width: '700px'}}>
                    <CheckListCard title={"My Wishes"} listid={"my-wishes"} editable={true}/>
                </div>
                {/* displays all self created wishlists stored in the database */}
                <div className="col-lg-6" style={{width: '700px'}}>

                    {wishlists.isSuccess ?
                        <OwnListsCard lists={wishlists.data}/> : "Loading..."}
                </div>
            </div>


        </WishlistBox>
    );
}

export default WishLists;