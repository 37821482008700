import React, {useState} from 'react';
import PageWrapper from "./components/PageWrapper";
import Dashboard from "./components/pages/app-pages/Dashboard";
import Login from "./components/pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import Calendar from "./components/pages/app-pages/Calendar";
import Planner from "./components/pages/app-pages/Planner";
import ShoppingList from "./components/pages/app-pages/ShoppingList";
import Food from "./components/pages/app-pages/Food";
import Problems from "./components/pages/app-pages/Problems";
import Commands from "./components/pages/app-pages/Commands";
import {HashRouter} from "react-router-dom";
import {Navigate, Route, Routes} from "react-router";
import CreditsShop from "./components/pages/app-pages/CreditsShop";
import 'bootstrap/dist/css/bootstrap.min.css'
import FoodPlanner from "./components/pages/app-pages/FoodPlanner";
import FoodData from "./components/pages/app-pages/FoodData";
import WishLists from "./components/pages/app-pages/WishLists";
import Money from "./components/pages/app-pages/Money";
import {QueryClient, QueryClientProvider, useMutation} from "react-query";
import {AddTodoModal} from "./components/modals/AddTodoModal";
import {submitNewGoal, submitNewTask} from "./api/todoApiHandler";
import {AddGoalModal} from "./components/modals/AddGoalModal";
import {CheckInModal} from "./components/modals/CheckInModal";
import {updateGoal, updateItem} from "./api/entityApiHandler";
import {createRoot} from "react-dom/client";
import {Goals} from "./components/pages/app-pages/Goals";
import AddRecipeModal from "./components/modals/AddRecipeModal";
import {GoalHistory} from "./components/pages/app-pages/GoalHistory";
import {Statistics} from "./components/pages/app-pages/Statistics";
import {Scanner} from "./components/pages/app-pages/Scanner";
import AddIngredientModal from "./components/modals/AddIngredientModal";

const queryClient = new QueryClient()

function App() {
    const [showingTaskModal, setShowTaskModal] = useState(false)
    const [showingGoalModal, setShowGoalModal] = useState(false)
    const [showingCheckInModal, setShowCheckInModal] = useState(false)
    const [todoToShow, setTodoToShow] = useState(null)
    const [goalToShow, setGoalToShow] = useState(null)
    const [checkInToShow, setCheckInToShow] = useState(null)

    const [showRecipeModal, setShowRecipeModal] = useState(false)
    const [showIngredientModal, setShowIngredientModal] = useState(false)

    const [recipeEditData, setRecipeEditData] = useState({
        ingredients: [], name: "", unit: "g", amount: "0"
    })
    const [ingredientEditData, setIngredientEditData] = useState({
        name: "", serving_size_amount: "100", serving_size_unit: "g", "stats": {
            "energy-kcal_100g": "",
            "carbohydrates_100g": "",
            "fat_100g": "",
            "proteins_100g": "",
            "energy-kcal_serving": "",
            "carbohydrates_serving": "",
            "fat_serving": "",
            "proteins_serving": "",
        },
        alternative_names: ""
    })


    const updateTodoMutation = useMutation({
            mutationFn: updateItem,
            onSuccess: () => {
                queryClient.invalidateQueries('todos')
            },
            onError: (error) => {
                alert("Could not update task. Check the server response for more information.")
            }
        }
    )

    const submitTodoMutation = useMutation({
        mutationFn: submitNewTask,
        onSuccess: () => {
            queryClient.invalidateQueries('todos')
        },
        onError: (error) => {
            alert("Could not create task. Check the server response for more information.")
        }
    })

    const updateGoalMutation = useMutation({
        mutationFn: updateGoal, onSuccess: () => {
            queryClient.invalidateQueries('projects')
        },
        onError: (error) => {
            alert("Could update goal. Check the server response for more information.")
        }
    })

    const submitNewGoalMutation = useMutation({
        mutationFn: submitNewGoal, onSuccess: () => {
            queryClient.invalidateQueries('projects')
        },
        onError: (error) => {
            alert("Could not create goal. Check the server response for more information.")
        }
    })

    const showTodoModal = (todo) => {
        setTodoToShow(todo)
        setShowTaskModal(true)
    }

    const showGoalModal = (goal) => {
        if (goal) {
            setGoalToShow(goal)
        } else {
            setGoalToShow(null)
        }
        setShowGoalModal(true)
    }

    const showCheckInModal = () => {
        setShowCheckInModal(true)
    }

    const closeTaskModal = () => {
        setTodoToShow(null)
        setShowTaskModal(false)
    }

    function saveTodo(todo) {
        if (todoToShow !== null) {
            todo["_id"] = todoToShow["_id"]
            todo["owner"] = todoToShow["owner"]
            todo["type"] = todoToShow["type"]
            const updatedTodo = Object.assign({}, todoToShow, todo);

            updateTodoMutation.mutate(updatedTodo)
        } else {
            submitTodoMutation.mutate(todo)
        }
    }

    function saveGoal(goal) {
        if (goalToShow !== null) {
            goal["_id"] = goalToShow["_id"]
            goal["owner"] = goalToShow["owner"]
            goal["type"] = goalToShow["type"]

            const updatedGoal = Object.assign({}, goalToShow, goal);

            updateGoalMutation.mutate(updatedGoal)
        } else {
            submitNewGoalMutation.mutate(goal)
        }
    }

    return <div>
        <HashRouter>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/app" element={<PrivateRoute>
                    <PageWrapper showTodoModal={showTodoModal} showCheckInModal={showCheckInModal}
                                 showGoalModal={showGoalModal}/>
                </PrivateRoute>}>
                    <Route path="dashboard" element={<Dashboard showTodoModal={showTodoModal}/>}/>
                    <Route path="calendar" element={<Calendar/>}/>
                    <Route path="planner" element={<Planner/>}/>
                    <Route path="creditsshop" element={<CreditsShop/>}/>
                    <Route path="goals" element={<Goals showGoalModal={showGoalModal}/>}/>
                    <Route path="goalhistory" element={<GoalHistory/>}/>
                    <Route path="statistics" element={<Statistics/>}/>
                    <Route path="shoplist" element={<ShoppingList/>}/>
                    <Route path="food" element={<Food/>}/>
                    <Route path="foodplanner" element={<FoodPlanner/>}/>
                    <Route path="fooddata" element={<FoodData showRecipeModal={() => setShowRecipeModal(true)}
                                                              setRecipeEditData={setRecipeEditData}
                                                              showIngredientModal={() => setShowIngredientModal(true)}
                                                              setIngredientEditData={setIngredientEditData}
                    />}/>
                    <Route path="scanner"
                           element={<Scanner showIngredientModal={() => setShowIngredientModal(true)}
                                             setIngredientEditData={setIngredientEditData}
                           />}/>
                    <Route path="money" element={<Money/>}/>
                    <Route path="wishlists" element={<WishLists/>}/>
                    <Route path="problems" element={<Problems/>}/>
                    <Route path="commands" element={<Commands/>}/>
                    <Route path="*" element={<Dashboard/>}/>
                </Route>
                <Route path="*" element={<Navigate to="/login"/>}/>
            </Routes>
        </HashRouter>
        <AddTodoModal todo={todoToShow} show={showingTaskModal} onClose={closeTaskModal}
                      onSave={saveTodo}/>
        <AddGoalModal goal={goalToShow} show={showingGoalModal} onClose={() => setShowGoalModal(false)}
                      onSave={saveGoal}/>
        <CheckInModal show={showingCheckInModal} onClose={() => setShowCheckInModal(false)}/>
        <AddRecipeModal show={showRecipeModal} onClose={() => setShowRecipeModal(false)}
                        editData={recipeEditData} setEditData={setRecipeEditData}
        />
        <AddIngredientModal show={showIngredientModal}
                            onClose={() => setShowIngredientModal(false)}
                            editData={ingredientEditData}
                            setEditData={setIngredientEditData}
        />
    </div>
}

function Main() {
    return <QueryClientProvider client={queryClient}>
        <App/>
    </QueryClientProvider>
}

const root = createRoot(document.getElementById('root'));
root.render(<Main/>);