import React from 'react';
import {authenticationService} from "../../../../services/authentication.service";
import Omnibar from "../../../Omnibar";
import {parseAndSubmitTask} from "../../../../api/todoApiHandler";
import profile from "../../../../img/undraw_profile.svg";
import {getCurrentTime} from "../../../../helpers/dateHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import {useMutation, useQueryClient} from "react-query";

function TopBar(props) {

    const queryClient = useQueryClient()

    const logout = () => {
        authenticationService.logout();
        window.location.href = "/#/login";
    }

    const submitTaskMutation = useMutation({
        mutationFn: parseAndSubmitTask, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        },
        onError: (error) => {
            alert("Could not create task. Check the server response for more information.")
        }
    })

    return (<div>
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3"
                    onClick={props.onSidebarToggle}>
                <i className="fa fa-bars"/>
            </button>

            <Omnibar onEmptySubmit={() => props.showTodoModal(null)} onSubmit={submitTaskMutation.mutate}
                     placeholder={"Create task ..."}/>
            <div className={"ml-1"}>
                <button className="btn btn-primary" onClick={(event) => {
                    event.preventDefault()
                    props.showCheckInModal()
                }}>Journal <FontAwesomeIcon icon={solid('journal-whills')}/></button>
            </div>
            <ul className="navbar-nav ml-auto">

                <li className="nav-item dropdown no-arrow d-sm-none">
                    <a className="nav-link dropdown-toggle" href="." id="searchDropdown"
                       role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw"/>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                         aria-labelledby="searchDropdown">

                        <Omnibar onEmptySubmit={() => props.showTaskModal()}
                                 onSubmit={parseAndSubmitTask}
                                 placeholder={"Create task ..."} resizeable={true}/>

                    </div>
                </li>
                {
                    ("Notification" in window && Notification.permission !== "granted") ?
                        <div className="nav-item">
                            <span className="nav-link">
                                <button
                                   className="mr-2 d-none text-decoration-underline d-lg-inline text-gray-600 small"
                                   onClick={(event) => {
                                         event.preventDefault()
                                       Notification.requestPermission().then((permission) => {
                                           if (permission === "granted") {
                                               new Notification("This is how you will be notified!")
                                           }
                                       })
                                   }}>
                                    Enable Notifications
                                </button>
                            </span>
                        </div> : null
                }

                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="." id="alertsDropdown"
                       role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-bell fa-fw"/>

                        {/*<span className="badge badge-danger badge-counter">3</span>*/}
                    </a>

                    <div
                        className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="alertsDropdown">
                        <h6 className="dropdown-header">
                            Alerts Center
                        </h6>
                        {/*
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-file-alt text-white"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span
                                        className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fas fa-donate text-white"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fas fa-exclamation-triangle text-white"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your
                                    account.
                                </div>
                            </a>
                            */}
                        <a className="dropdown-item text-center small text-gray-500" href=".">Show
                            All
                            Alerts</a>
                    </div>
                </li>

                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="." id="messagesDropdown"
                       role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-envelope fa-fw"/>

                        {/*<span className="badge badge-danger badge-counter">9</span>*/}
                    </a>
                    <div
                        className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="messagesDropdown">
                        <h6 className="dropdown-header">
                            Message Center
                        </h6>
                        {/*
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src={profile1} alt=""/>
                                    <div className="status-indicator bg-success">
                                    </div>
                                </div>
                                <div className="font-weight-bold">
                                    <div className="text-truncate">Hi there! I am wondering if you can
                                        help
                                        me with a
                                        problem I've been having.
                                    </div>
                                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src={profile2} alt=""/>
                                    <div className="status-indicator">
                                    </div>
                                </div>
                                <div>
                                    <div className="text-truncate">I have the photos that you ordered last
                                        month, how
                                        would you like them sent to you?
                                    </div>
                                    <div className="small text-gray-500">Jae Chun · 1d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src={profile3}
                                         alt=""/>
                                    <div className="status-indicator bg-warning">
                                    </div>
                                </div>
                                <div>
                                    <div className="text-truncate">Last month's report looks great, I am
                                        very happy with
                                        the progress so far, keep up the good work!
                                    </div>
                                    <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href=".">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle"
                                         src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                         alt=""/>
                                    <div className="status-indicator bg-success">
                                    </div>
                                </div>
                                <div>
                                    <div className="text-truncate">Am I a good boy? The reason I ask is
                                        because someone
                                        told me that people say this to all dogs, even if they aren't
                                        good...
                                    </div>
                                    <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                </div>
                            </a>
                            */}
                        <a className="dropdown-item text-center small text-gray-500" href=".">Read More
                            Messages</a>
                    </div>
                </li>

                <div className="topbar-divider d-none d-sm-block"/>
                <div className="nav-item">
                    <a className="nav-link" href=".">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                            {getCurrentTime().format("dd, D.M.YYYY, HH:mm")}
                        </span>
                    </a>
                </div>
                <div className="topbar-divider d-none d-sm-block"/>
                <div className="nav-item">
                    <a className="nav-link" href=".">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                            {props.credits + " "}
                            <FontAwesomeIcon icon={solid('coins')}/>
                        </span>
                    </a>
                </div>
                <div className="topbar-divider d-none d-sm-block"/>

                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="." id="userDropdown" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span
                                            className="mr-2 d-none d-lg-inline text-gray-600 small">{authenticationService.currentUserValue.name}</span>
                        <img className="img-profile rounded-circle"
                             src={profile} alt=""/>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                         aria-labelledby="userDropdown">
                        {/*
                            <a className="dropdown-item" href=".">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"/>
                                Profile
                            </a>
                            <a className="dropdown-item" href=".">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"/>
                                Settings
                            </a>
                            <a className="dropdown-item" href=".">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"/>
                                Activity Log
                            </a>
                            <div className="dropdown-divider"></div>
                            */}
                        <button className="dropdown-item" onClick={logout} data-toggle="modal"
                                data-target="#logoutModal">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>
                            Logout
                        </button>
                    </div>
                </li>

            </ul>

        </nav>
    </div>);
}

export default TopBar;
