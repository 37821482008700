import React from 'react';
import styled from "styled-components";
import moment from "moment";
import {FoodPlannerWeekday} from "./FoodPlannerWeekday";

const Container = styled.div`
    display: flex;
    background: white;
    align-content: center;
    margin: 0 0 8px;
`

function FoodPlannerCard(props) {
    return <Container>
        {[0, 1, 2, 3, 4, 5, 6].map((id, index) => {
            let date = moment.utc(props.date).add(id, "days")

            return <FoodPlannerWeekday key={id} plannedFood={props.plannedFood[id]}
                                       ingredients={props.ingredients} recipes={props.recipes}
                                       index={index.toString()}
                                       date={date} stats={props.stats[id]}/>
        })}
    </Container>;
}

export default FoodPlannerCard