import React, {useState} from 'react';
import Sidebar from "./pages/app-pages/common/Sidebar";
import TopBar from "./pages/app-pages/common/TopBar";
import Footer from "./pages/app-pages/common/Footer";
import {Outlet} from "react-router";
import {fetchCredits} from "../api/creditsApiHandler";
import {useQuery} from "react-query";

export default function PageWrapper(props) {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true)
    const credits = useQuery('credits', fetchCredits)

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed)
    }

    return (<div id="wrapper">
        <Sidebar onSidebarToggle={toggleSidebar} toggled={sidebarCollapsed}/>
        <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
                <TopBar credits={credits.isSuccess ? credits.data : "Loading..."} showTodoModal={props.showTodoModal}
                        showGoalModal={props.showGoalModal} showCheckInModal={props.showCheckInModal}
                        onSidebarToggle={toggleSidebar}/>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    </div>);
}