import {authHeader} from "./authHeader";
import {parseTask} from "../parser/TaskParser";
import {handleResponse} from "./handleResponse";
import {sortTodos} from "../helpers/helpers";
import {updateItem} from "./entityApiHandler";
import moment from "moment"
import {mongoToMoment} from "../helpers/dateHelper";

export function submitNewTask(taskDict) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(taskDict),
    };
    return fetch(process.env.REACT_APP_API_URL + '/todos/create', requestOptions)
        .then(handleResponse);
}

export function submitNewGoal(goalDict) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(goalDict),
    };

    return fetch(process.env.REACT_APP_API_URL + '/projects', requestOptions)
        .then(handleResponse);
}

export function deleteGoal(goal) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/projects/' + goal._id["$oid"], requestOptions)
        .then(handleResponse);
}

export function checkIn(checkInDict, date) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(checkInDict),
    };

    return fetch(process.env.REACT_APP_API_URL + '/checkin?date=' + date.format('YYYY-MM-DD'), requestOptions)
        .then(handleResponse);
}


export function parseAndSubmitTask(entity) {
    const words = entity.split(' ')
    return submitNewTask(parseTask(words));
}

export function checkOffTodo(item) {
    item.done = !item.done;

    if ("planned_after" in item && mongoToMoment(item.planned_after) > moment()) {
        item.planned_after = moment().unix()
    }
    if ("start_time" in item && mongoToMoment(item.start_time) > moment()) {
        item.start_time = moment().unix()
    }

    if (!("duration" in item) && "start_time" in item) {
        item.duration = moment().unix() - mongoToMoment(item.start_time).unix()
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(item),
    };

    let url
    if (item._id && !("repetition" in item)) {
        url = process.env.REACT_APP_API_URL + '/todos/checkoff/' + item._id["$oid"]
    } else if (!("_id" in item) && "repetition" in item) {
        url = process.env.REACT_APP_API_URL + '/todos/checkoff/' + item.repetition["id"] + "/" + item.repetition["index"]
    } else {
        throw new Error("Invalid item")
    }

    return fetch(url, requestOptions)
        .then(handleResponse);
}

export function startTodoNow(item) {
    item.start_time = moment().unix()
    item.duration = null

    return updateItem(item)
}

export function planTodoAt({item, date}) {
    item.planned_after = date.unix()

    return updateItem(item)
}

export function unplanTodo(item) {
    item.planned_after = null
    item.start_time = null

    return updateItem(item)
}

export function fetchInbox() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/todos/inbox', requestOptions)
        .then(handleResponse)
        .then(data => {
                data.sort(function (a, b) {
                    return ("priority" in b ? b.priority : 0) - ("priority" in a ? a.priority : 0)
                })
                return data;
            }
        );
}

export function fetchRecommendations(limited) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/todos/recommendations?' + (limited ? "limited" : ""), requestOptions)
        .then(handleResponse)
        .then(data => {
                data.sort(function (a, b) {
                    let prio_diff = ("priority" in b ? b.priority : 0) - ("priority" in a ? a.priority : 0)

                    if (prio_diff === 0) {
                        return ('' + a.project).localeCompare(b.project);
                    } else {
                        return prio_diff
                    }
                })
                return data;
            }
        );
}

export function fetchOverdue() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/todos/overdue', requestOptions)
        .then(handleResponse)
        .then(data => {
                data.sort(function (a, b) {
                    let prio_diff = ("priority" in b ? b.priority : 0) - ("priority" in a ? a.priority : 0)

                    if (prio_diff === 0) {
                        return ('' + a.project).localeCompare(b.project);
                    } else {
                        return prio_diff
                    }
                })
            return data
            }
        );
}

export function fetchUpcoming() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify({
            day: moment().unix() + (-4 * 60 - 30 + 24 * 60) * 60,
        })
    };

    return fetch(process.env.REACT_APP_API_URL + '/todos/query', requestOptions)
        .then(handleResponse).then(data => {
            data.sort(sortTodos)
            return data
        }
    );
}

export function getCheckins() {
    return fetch(process.env.REACT_APP_API_URL + '/checkin/stats', {
        method: 'GET', headers: authHeader()
    }).then(handleResponse)
}