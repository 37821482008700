import React, {useState} from 'react';
import PlannerCard from "../../cards/PlannerCard";
import {getMorningTime, momentToMongo, mongoToMoment} from "../../../helpers/dateHelper";
import {fetchInbox, fetchOverdue, fetchRecommendations} from "../../../api/todoApiHandler";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";
import {sortTodos} from "../../../helpers/helpers";
import {DragDropContext} from "@hello-pangea/dnd";
import TaskListCard from "../../cards/TaskListCard";
import DatepickerCard from "../../cards/DatepickerCard";
import {updateItem} from "../../../api/entityApiHandler";
import moment from "moment";
import {useMutation, useQuery, useQueryClient} from "react-query";


export default function Planner(props) {
    const queryClient = useQueryClient()
    const fetchTodos = () => {
        const todoPromises = []
        for (let i = 0; i < 7; i++) {
            let queryDict = {
                day: date.unix() + i * 60 * 60 * 24,
                done: false,
            };

            const requestOptions = {
                method: 'POST',
                headers: authHeader({'Content-Type': 'application/json'}),
                body: JSON.stringify(queryDict)
            };

            todoPromises.push(fetch(process.env.REACT_APP_API_URL + '/todos/query', requestOptions)
                .then(handleResponse)
                .then(data => {
                        data.sort(sortTodos)
                        return data
                    }
                ))
        }
        return Promise.all(todoPromises).then(data => {
            return data
        })
    }


    const [selectedProject, setSelectedProject] = useState(null)
    const [date, setDate] = useState(getMorningTime())
    const todos = useQuery({
        queryKey: ['todos', "week", date.unix()],
        queryFn: fetchTodos,
        initialData: [[], [], [], [], [], [], [], []]
    })
    const overdue = useQuery(['todos', 'overdue'], fetchOverdue)
    const inbox = useQuery(['todos', 'inbox'], fetchInbox)
    const recommendations = useQuery(['todos', 'recommendations', 'by-project'], (limited) => fetchRecommendations(limited).then(data => {
        let recommendations = {}
        for (let item of data) {
            if (!(item["project"] in recommendations)) {
                recommendations[item["project"]] = []
            }
            recommendations[item["project"]].push(item)
        }
        return recommendations
    }))

    const updateitemMutation = useMutation(updateItem, {
        onSuccess: () => {
            queryClient.invalidateQueries(['todos'])
        }
    })

    const onDragEnd = result => {
        const {destination, source} = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        if (destination.droppableId === "inbox" || destination.droppableId.startsWith("project") || destination.droppableId === "overdue") {
            return;
        }

        let sourcecolumn
        let project
        if (source.droppableId === "inbox") {
            sourcecolumn = inbox.data
        } else if (source.droppableId.startsWith("project")) {
            project = source.droppableId.substring(source.droppableId.indexOf('-') + 1);
            sourcecolumn = recommendations.data[project]
        } else if (source.droppableId === "overdue") {
            sourcecolumn = overdue.data
        } else {
            sourcecolumn = todos.data[source.droppableId];
        }
        const targetcolumn = todos.data[destination.droppableId];
        const newSourceColumn = Array.from(sourcecolumn)
        const newTargetColumn = sourcecolumn === targetcolumn ? newSourceColumn : Array.from(targetcolumn)
        const newTodos = Array.from(todos.data)
        const todo = sourcecolumn[source.index]

        let predecessor_index;
        if (sourcecolumn === targetcolumn && destination.index > source.index) {
            predecessor_index = destination.index;
        } else {
            predecessor_index = destination.index - 1;
        }

        let todo_planned_after
        if (predecessor_index >= 0) {
            let predecessor = targetcolumn[predecessor_index];
            let duration = predecessor["duration"];
            if (!duration) {
                duration = 5 * 60
            }

            if (targetcolumn[predecessor_index]["start_time"]) {
                todo_planned_after = mongoToMoment(predecessor["start_time"]).add(duration, "seconds")
            } else if (targetcolumn[predecessor_index]["planned_after"]) {
                todo_planned_after = mongoToMoment(predecessor["planned_after"]).add(duration, "seconds")
            } else {
                console.error("Item does not have planned time or start time")
                return;
            }
        } else {
            todo_planned_after = moment(date).add(destination.droppableId, "days")
        }

        let toUpdate = [{"item": todo, "start_time": null, "planned_after": momentToMongo(todo_planned_after)}]
        if (!("duration" in todo)) {
            toUpdate["duration"] = 5 * 60
        } else {
            toUpdate["duration"] = todo.duration
        }

        let successor_index;
        if (sourcecolumn === targetcolumn && destination.index > source.index) {
            successor_index = destination.index + 1;
        } else if (sourcecolumn === targetcolumn) {
            successor_index = destination.index;
        } else {
            successor_index = destination.index;
        }

        let blockedUntil = moment(todo_planned_after).add(toUpdate["duration"], "seconds")
        while (successor_index < targetcolumn.length) {
            let nextItem = JSON.parse(JSON.stringify(targetcolumn[successor_index]))
            if (nextItem["_id"]["$oid"] === todo["_id"]["$oid"]) {
                successor_index++
            } else if ("start_time" in nextItem) {
                if (mongoToMoment(nextItem.start_time) >= blockedUntil) {
                    break
                } else {
                    console.error("Planning not possible, start time interferes")
                    return;
                }
            } else if ("planned_after" in nextItem) {
                if (mongoToMoment(nextItem.planned_after) >= blockedUntil) {
                    break
                } else {
                    nextItem.planned_after = momentToMongo(blockedUntil)
                    if (!("duration" in nextItem)) {
                        nextItem.duration = 5 * 60
                    }
                    toUpdate.push({
                        "item": targetcolumn[successor_index],
                        "planned_after": momentToMongo(blockedUntil),
                        "duration": nextItem.duration
                    })
                    blockedUntil = blockedUntil.add(nextItem.duration, "seconds")
                    successor_index++
                }
            } else {
                console.error("Item does not have planned time or start time")
                console.error(nextItem)
                return;
            }
        }

        for (let item of toUpdate) {
            item["item"].planned_after = item["planned_after"]
            if ("duration" in item) {
                item["item"].duration = item["duration"]
            }
            if ("start_time" in item) {
                item["item"].start_time = item["start_time"]
            }
            updateitemMutation.mutate(item["item"])
        }

        newSourceColumn.splice(source.index, 1)
        newTargetColumn.splice(destination.index, 0, todo)

        let newInbox
        let newRecommendations
        let newOverdue
        if (source.droppableId === "inbox") {
            newInbox = newSourceColumn
            newRecommendations = recommendations.data
            newOverdue = overdue.data
        } else if (source.droppableId.startsWith("project")) {
            newRecommendations = recommendations.data
            newRecommendations[project] = newSourceColumn
            newInbox = inbox
            newOverdue = overdue.data
        } else if (source.droppableId === "overdue") {
            newRecommendations = recommendations.data
            newInbox = inbox
            newOverdue = newSourceColumn
        } else {
            newInbox = inbox
            newRecommendations = recommendations.data
            newTodos.splice(source.droppableId, 1)
            newTodos.splice(source.droppableId, 0, newSourceColumn)
        }

        newTodos.splice(destination.droppableId, 1)
        newTodos.splice(destination.droppableId, 0, newTargetColumn)

        queryClient.setQueryData('todos', newTodos)
        queryClient.setQueryData('inbox', newInbox)
        queryClient.setQueryData('recommendations', newRecommendations)
        queryClient.setQueryData('overdue', newOverdue)
    };

    if (!todos.isSuccess || !overdue.isSuccess || !inbox.isSuccess || !recommendations.isSuccess) {
        const loadingComponents = [];

        if (!todos.isSuccess) {
            loadingComponents.push('Todos');
        }

        if (!overdue.isSuccess) {
            loadingComponents.push('Overdue');
        }

        if (!inbox.isSuccess) {
            loadingComponents.push('Inbox');
        }

        if (!recommendations.isSuccess) {
            loadingComponents.push('Recommendations');
        }

        return <div>Loading {loadingComponents.join(', ')}...</div>;
    }

    return (

        <div className="container-fluid">
            <DragDropContext onDragEnd={onDragEnd}>
                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <DatepickerCard title={"Your week"} empty={false} currentDate={date}
                                            daysPerClick={7}
                                            changeDate={(date) => {
                                                setDate(date)
                                            }}>
                                <PlannerCard todos={todos.data} date={date}/>
                            </DatepickerCard>
                        </div>
                    </div>
                    <div className="row">
                        {selectedProject === null && overdue && overdue.length !== 0 ?
                            <div className="col-lg-2 mb-4">
                                <TaskListCard title={"Overdue"}
                                              id={"overdue"}
                                              currentDate={date}
                                              draggable={true}
                                              tasks={overdue.data}/>
                            </div> : ""}
                        {selectedProject === null && inbox && inbox.length !== 0 ?
                            <div className="col-lg-2 mb-4">
                                <TaskListCard title={"Your Inbox"}
                                              id={"inbox"}
                                              currentDate={date}
                                              draggable={true}
                                              tasks={inbox.data}/>
                            </div> : ""}
                        {
                            selectedProject === null ?
                                Object.entries(recommendations.data).map(([key, value]) => {
                                    return <div className="col-lg-2" key={"project-" + key}>
                                        <TaskListCard
                                            link={() => setSelectedProject(key)}
                                            title={key}
                                            id={"project-" + key}
                                            currentDate={date}
                                            draggable={true}
                                            tasks={value}/>
                                    </div>
                                }) :
                                Object.entries(recommendations.data).map(([key, value]) => {
                                    if (key === selectedProject || key.startsWith(selectedProject + ".")) {
                                        return <div className="col-lg-3" key={"project-" + key}>
                                            <TaskListCard
                                                link={() => setSelectedProject(null)}
                                                title={key}
                                                id={"project-" + key}
                                                currentDate={date}
                                                draggable={true}
                                                tasks={value}/>
                                        </div>
                                    }
                                })
                        }
                    </div>
                </div>
            </DragDropContext>
        </div>

    );
}

