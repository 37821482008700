import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {generateTodo} from "../../randomWord";
import HoverableInfoIcon from "../../helpers/HoverableInfoIcon";
import {parseDuration, parseTime, unparseDuration} from "../../parser/TaskParser";
import moment from "moment/moment";
import {getCurrentTime} from "../../helpers/dateHelper";

export function AddTodoModal(props) {
    const [name, setName] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [project, setProject] = React.useState("")
    const [planned_after, setPlannedAfter] = React.useState("")
    const [start_time, setStartTime] = React.useState("")
    const [duration, setDuration] = React.useState("")
    const [done, setDone] = React.useState(false);
    const [type, setType] = React.useState("task")
    const [priority, setPriority] = React.useState(0)
    const [bounty, setBounty] = React.useState(0)
    const [shownTodoId, setShownTodoId] = React.useState(-1)

    if (props.todo && shownTodoId !== props.todo.id) {
        setName(props.todo.name)
        setDescription(props.todo.description)
        if (props.todo.project) {
            setProject(props.todo.project)
        }
        if (props.todo.planned_after) {
            setPlannedAfter(moment(props.todo.planned_after["$date"]).format("YYYY-MM-DDTHH:mm"))
        }
        if (props.todo.start_time) {
            setStartTime(moment(props.todo.start_time["$date"]).format("YYYY-MM-DDTHH:mm"))
        }
        if (props.todo.duration) {
            setDuration(unparseDuration(props.todo))
        }
        setDone(props.todo.done ? props.todo.done : false)
        console.assert(props.todo.type === "task")
        setPriority(props.todo.priority ? props.todo.priority : 0)
        setBounty(props.todo.bounty ? props.todo.bounty : 0)

        setShownTodoId(props.todo.id)
    }

    const close = () => {
        clear()
        props.onClose()
    }

    const clear = () => {
        setName("")
        setDescription("")
        setProject("")
        setPlannedAfter(getCurrentTime().format("YYYY-MM-DDTHH:mm"))
        setStartTime("")
        setDuration("")
        setDone(false)
        setType("task")
        setPriority(0)
        setBounty(0)
        setShownTodoId(-1)
    }

    const submit = () => {
        let submitState = {
            name: name,
            description: description,
            done: done,
            type: type,
            priority: priority,
        }
        if (project !== "") {
            submitState.project = project
        }
        if (planned_after !== "") {
            submitState.planned_after = parseTime(planned_after)
        }
        if (start_time !== "") {
            submitState.start_time = parseTime(start_time)
        }
        if (duration && duration !== "") {
            parseDuration(submitState, duration)
        }

        if (bounty !== 0) {
            submitState.bounty = bounty
        }

        props.onSave(submitState)
        clear()
    }
    return <Modal size="xl" show={props.show}>
        {/* TODO close button <Modal.Header closeButton>*/}
        <Modal.Header>
            <Modal.Title>New Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control placeholder={generateTodo()} autoFocus value={name}
                                  onChange={(event) => {
                                      setName(event.target.value)
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Project</Form.Label>
                    <Form.Control value={project} onChange={(e) => {
                        setProject(e.target.value)
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Planned Time</Form.Label>
                    <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                        <HoverableInfoIcon
                            text="The earliest time after which you want the task on the todo list. Leave empty if you only want it to show in the projects and plan it later. Examples: 10.05. or Mo or 10:30 or 10.06.2022_18:30"/>
                    </Form.Label>
                    <Form.Control aria-label="Date and time" type="datetime-local" placeholder={"e.g. Mo"} value={planned_after} onChange={(e) => {
                        setPlannedAfter(e.target.value)
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                <Form.Label>Start Time</Form.Label>
                    <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                        <HoverableInfoIcon
                            text="The time at which the event or task will start (Leave empty if it is a flexible task). Examples: 10.05. or Mo or 10:30 or 10.06.2022_18:30"/>
                    </Form.Label>
                    <Form.Control aria-label="Date and time" placeholder={"e.g. 15:00"} type="datetime-local" value={start_time} onChange={(e) => {
                        setStartTime(e.target.value)
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Duration</Form.Label>
                    <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                        <HoverableInfoIcon
                            text="The duration of the event or task. Defaults to 5m. Examples: 1h or 1h30m"/>
                    </Form.Label>
                    <Form.Control placeholder={"e.g. 5m"} value={duration}
                                  onChange={(e) => {
                                      setDuration(e.target.value)
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check type="checkbox" label={<div>Done<Form.Label className="text-muted"
                                                                            style={{paddingLeft: "0.5em"}}>
                        <HoverableInfoIcon text="Whether the task is done or not."/> </Form.Label></div>}
                                checked={done} onChange={(e) => {
                        setDone(e.target.checked)
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Priority</Form.Label>
                    <Form.Select value={priority} onChange={(e) => {
                        setPriority(e.target.value)
                    }}>
                        <option value={-3}>Lowest</option>
                        <option value={-2}>Lower</option>
                        <option value={-1}>Low</option>
                        <option value={0}>Default</option>
                        <option value={1}>High</option>
                        <option value={2}>Higher</option>
                        <option value={3}>Highest</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Bounty</Form.Label>
                    <Form.Label className="text-muted" style={{paddingLeft: "0.5em"}}>
                        <HoverableInfoIcon
                            text="The bounty which should be awarded when finishing the task."/>
                    </Form.Label>
                    <Form.Control value={bounty} onChange={(e) => {
                        setBounty(e.target.value)
                    }}/>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={close}>
                Close
            </Button>
            <Button variant="primary" onClick={() => {
                submit()
                close()
            }}>
                Save Task
            </Button>
            <Button variant="primary" onClick={submit}>
                Save Task and Add Another
            </Button>
        </Modal.Footer>
    </Modal>;
}