import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Col, Container, InputGroup, Row} from "react-bootstrap";
import {fetchFooddb, submitIngredient} from "../../api/foodApiHandler";
import {convertDBItem} from "../../helpers/helpers";
import Autocomplete from "react-autocomplete";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IngredientBox} from "../IngredientBox";
import {useMutation, useQueryClient} from "react-query";

const wrapperProps = {
    className: "bg-light",
    style: {
        position: "relative",
        flex: "1 1 auto",
        width: 90 + "%",
        minWidth: 0,
        marginBottom: 5 + 'px',
        marginTop: 5 + 'px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    }
};

const menuStyle = {
    fontSize: '90%', position: 'absolute', zIndex: 100, maxHeight: '50%', top: 40, left: 0
};

function renderAmount(amount) {
    if (amount === null) {
        return ""
        // } else if (amount === "") {
        //     return ""
    } else {
        return amount
        // return parseFloat(amount).toFixed(2)
    }
}

function formatAmount(amount) {
    if (amount === null) {
        return ""
    } else if (amount === "") {
        return ""
    } else {
        return parseFloat(amount).toFixed(2)
    }
}

function AddIngredientModal({show, onClose, editData, setEditData}) {
    const [validated, setValidated] = React.useState(false);
    const [searching, setSearching] = React.useState(false)
    const [changed100g, setChanged100g] = React.useState(true)
    const [changedServing, setChangedServing] = React.useState(true)
    const [cachedResults, setCachedResults] = React.useState([])

    const queryClient = useQueryClient()
    const submitIngredientMutation = useMutation({
        mutationFn: submitIngredient,
        onSuccess: () => {
            queryClient.invalidateQueries(['food', 'ingredients'])
        }
    })

    function updateStats(ingredient, newChanged100g = changed100g, newChangedServing = changedServing) {
        if (!newChanged100g && (ingredient["stats"]["energy-kcal_serving"] !== null)) {
            ingredient["stats"]["energy-kcal_100g"] = formatAmount(ingredient["stats"]["energy-kcal_serving"] / ingredient["serving_size_amount"] * 100)
        }
        if (!newChanged100g && (ingredient["stats"]["carbohydrates_serving"] !== null)) {
            ingredient["stats"]["carbohydrates_100g"] = formatAmount(ingredient["stats"]["carbohydrates_serving"] / ingredient["serving_size_amount"] * 100)
        }
        if (!newChanged100g && (ingredient["stats"]["fat_serving"] !== null)) {
            ingredient["stats"]["fat_100g"] = formatAmount(ingredient["stats"]["fat_serving"] / ingredient["serving_size_amount"] * 100)
        }
        if (!newChanged100g && (ingredient["stats"]["proteins_serving"] !== null)) {
            ingredient["stats"]["proteins_100g"] = formatAmount(ingredient["stats"]["proteins_serving"] / ingredient["serving_size_amount"] * 100)
        }
        if (!newChangedServing && (ingredient["stats"]["energy-kcal_100g"] !== null)) {
            ingredient["stats"]["energy-kcal_serving"] = formatAmount(ingredient["stats"]["energy-kcal_100g"] / 100 * ingredient["serving_size_amount"])
        }
        if (!newChangedServing && (ingredient["stats"]["carbohydrates_100g"] !== null)) {
            ingredient["stats"]["carbohydrates_serving"] = formatAmount(ingredient["stats"]["carbohydrates_100g"] / 100 * ingredient["serving_size_amount"])
        }
        if (!newChangedServing && (ingredient["stats"]["fat_100g"] !== null)) {
            ingredient["stats"]["fat_serving"] = formatAmount(ingredient["stats"]["fat_100g"] / 100 * ingredient["serving_size_amount"])
        }
        if (!newChangedServing && (ingredient["stats"]["proteins_100g"] !== null)) {
            ingredient["stats"]["proteins_serving"] = formatAmount(ingredient["stats"]["proteins_100g"] / 100 * ingredient["serving_size_amount"])
        }
    }

    function onSelect(e, item) {
        let empty = {
            name: "",
            serving_size_amount: "100",
            serving_size_unit: "g",
            "stats": {}
        }
        let newIngredient = {
            ...empty,
            ...item,
        }
        newIngredient["stats"] = {
            ...item["stats"]
        }
        const stats_100g = ["energy-kcal_100g", "carbohydrates_100g", "fat_100g", "proteins_100g"]
        const stats_serving = ["energy-kcal_serving", "carbohydrates_serving", "fat_serving", "proteins_serving"]
        let changed100g = false
        let changedServing = false
        for (let stat of stats_100g) {
            if (stat in item["stats"] && item["stats"][stat] !== "") {
                changed100g = true
                newIngredient["stats"][stat] = formatAmount(item["stats"][stat])
            } else {
                newIngredient["stats"][stat] = null
            }
        }
        for (let stat of stats_serving) {
            if (stat in item["stats"] && item["stats"][stat] !== "") {
                changedServing = true
                newIngredient["stats"][stat] = formatAmount(item["stats"][stat])
            } else {
                newIngredient["stats"][stat] = null
            }
        }

        updateStats(newIngredient, changed100g, changedServing);
        setEditData(newIngredient)
        setChanged100g(changed100g)
        setChangedServing(changedServing)
    }

    function clearData() {
        setEditData({
            name: "", serving_size_amount: "100", serving_size_unit: "g", "stats": {
                "energy-kcal_100g": "",
                "carbohydrates_100g": "",
                "fat_100g": "",
                "proteins_100g": "",
                "energy-kcal_serving": "",
                "carbohydrates_serving": "",
                "fat_serving": "",
                "proteins_serving": "",
            },
            alternative_names: ""
        })
        setChangedServing(true)
        setChanged100g(true)
        onClose()
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setValidated(true)
            submitIngredientMutation.mutate(editData)
            clearData()
            onClose()
        }
    }

    return <Modal size="xl" show={show}>
        <Form validated={validated} onSubmit={handleSubmit}>
            <Modal.Header closeButton onHide={onClose}>
                <Modal.Title>New Ingredient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Container>
                        <Row>
                            <Col>
                                <InputGroup className="mb-3" hasValidation>
                                    <Form.Label>Ingredient name</Form.Label>
                                    <Autocomplete
                                        getItemValue={(item) => {
                                            if (item.name) {
                                                return item.name;
                                            } else {
                                                console.error("Item is missing a name.")
                                                return "???"
                                            }
                                        }}
                                        items={cachedResults}
                                        wrapperProps={wrapperProps}
                                        isItemSelectable={function () {
                                            return true
                                        }}
                                        menuStyle={menuStyle}
                                        renderItem={(item, isHighlighted) => <div key={item["db_id"]}>
                                            <IngredientBox highlighted={isHighlighted}
                                                           item={item}/>
                                        </div>}
                                        renderInput={props => <Form.Control autoFocus
                                                                            required
                                                                            placeholder=""
                                                                            style={{width: 100 + '%'}}
                                                                            className="form-control bg-light border-0" {...props}
                                                                            onSubmit={(event) => {
                                                                                event.preventDefault()
                                                                            }}
                                        />}
                                        value={editData["name"]}
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["name"] = event.target.value
                                            setEditData({...ingredient})
                                        }}
                                        onSelect={onSelect}
                                    />
                                    <Button className="btn btn-primary" onClick={(event) => {
                                        event.preventDefault()
                                        fetchFooddb(editData["name"]).then((results) => {
                                            const cachedResults = results["products"].map((product) => convertDBItem(product))
                                            setCachedResults(cachedResults.filter((item) => item !== null))
                                            setSearching(false)
                                        })
                                        setSearching(true)
                                    }}>
                                        {
                                            searching ?
                                                <FontAwesomeIcon icon={solid("spinner")} spin/>
                                                :
                                                <FontAwesomeIcon icon={solid('magnifying-glass')}
                                                                 title={"Search food db"}/>
                                        }
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Serving size</Form.Label>
                                <InputGroup className="mb-3" hasValidation>
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        type={"text"}
                                        placeholder="Serving size"
                                        onChange={(event) => {
                                            const re = /^[0-9.]+$/
                                            let value = event.target.value
                                            if (value === '' || re.test(value)) {
                                                const newEditData = {
                                                    ...editData,
                                                    serving_size_amount: value
                                                }
                                                updateStats(newEditData, changed100g, changedServing)
                                                setEditData(newEditData)
                                            }
                                        }}
                                        value={editData["serving_size_amount"]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Currently, a serving size is required.
                                    </Form.Control.Feedback>
                                    <InputGroup.Text
                                        id="basic-addon1">{editData["serving_size_unit"]}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Alternative name(s) for search (separated by semicolon)</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder=""
                                        onChange={(event) => {
                                            setEditData({...editData, "alternative_names": event.target.value})
                                        }}
                                        value={editData["alternative_names"]}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col><b>Per 100g</b></Col>
                            <InputGroup className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Calculate from serving"
                                    checked={!changed100g}
                                    onChange={(event) => {
                                        let ingredient = editData
                                        updateStats(ingredient, !event.target.checked, changedServing);
                                        setEditData({...ingredient})
                                        setChanged100g(!event.target.checked)
                                        if (event.target.checked) {
                                            setChangedServing(true)
                                        }
                                    }}
                                />
                            </InputGroup>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Calories</Form.Label>
                                <InputGroup className="mb-3" hasValidation>
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Calories"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["energy-kcal_100g"] = event.target.value
                                            updateStats(ingredient)
                                            setEditData({...ingredient})
                                            setChanged100g(true)
                                        }}
                                        value={renderAmount(editData["stats"]["energy-kcal_100g"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">kcal</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Carbohydrates</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Carbohydrates"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["carbohydrates_100g"] = event.target.value
                                            updateStats(ingredient)
                                            setEditData({...ingredient})
                                            setChanged100g(true)
                                        }}
                                        value={renderAmount(editData["stats"]["carbohydrates_100g"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">g</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Fat</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Fat"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["fat_100g"] = event.target.value
                                            updateStats(ingredient)
                                            setEditData({...ingredient})
                                            setChanged100g(true)
                                        }}
                                        value={renderAmount(editData["stats"]["fat_100g"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">g</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Protein</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Protein"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["proteins_100g"] = event.target.value
                                            updateStats(ingredient)
                                            setEditData({...ingredient})
                                            setChanged100g(true)
                                        }}
                                        value={renderAmount(editData["stats"]["proteins_100g"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">g</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <b>Per serving</b>
                                <InputGroup className="mb-3">
                                    <Form.Check
                                        inputMode={"decimal"}
                                        type="checkbox"
                                        label="Calculate from 100g"
                                        checked={!changedServing}
                                        onChange={(event) => {
                                            let ingredient = editData
                                            updateStats(ingredient, changed100g, !event.target.checked)
                                            setEditData({...ingredient})
                                            setChangedServing(!event.target.checked)
                                            if (event.target.checked) {
                                                setChanged100g(true)
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Calories</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Calories"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["energy-kcal_serving"] = event.target.value
                                            updateStats(ingredient)
                                            setEditData({...ingredient})
                                            setChangedServing(true)
                                        }}
                                        value={renderAmount(editData["stats"]["energy-kcal_serving"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">kcal</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Carbohydrates</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Carbohydrates"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["carbohydrates_serving"] = event.target.value
                                            updateStats(ingredient)
                                            setEditData({...ingredient})
                                            setChangedServing(true)
                                        }}
                                        value={renderAmount(editData["stats"]["carbohydrates_serving"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">g</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Fat</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Fat"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["fat_serving"] = event.target.value
                                            updateStats(ingredient);
                                            setEditData({...ingredient})
                                            setChangedServing(true)
                                        }}
                                        value={renderAmount(editData["stats"]["fat_serving"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">g</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Protein</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        required
                                        inputMode={"decimal"}
                                        placeholder="Protein"
                                        onChange={(event) => {
                                            let ingredient = editData
                                            ingredient["stats"]["proteins_serving"] = event.target.value
                                            updateStats(ingredient);
                                            setEditData({...ingredient})
                                            setChangedServing(true)
                                        }}
                                        value={renderAmount(editData["stats"]["proteins_serving"])}
                                    />
                                    <InputGroup.Text id="basic-addon1">g</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    clearData();
                    return onClose();
                }}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}

export default AddIngredientModal;