import {errorWrap} from "../../helpers/helpers";
import {checkOffTodo, deleteGoal, planTodoAt, startTodoNow, unplanTodo} from "../../api/todoApiHandler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {checkOffListItem, deleteList} from "../../api/listApiHandler";
import {deleteEntity} from "../../api/entityApiHandler";
import {repairTodo} from "../../api/adminApiHandler";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {Dropdown} from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export const btnoptions = Object.freeze({
    "CHECK_OFF_TODO": 0,
    "PLAN_NOW": 1,
    "DELETE": 2,
    "START_NOW": 3,
    "PORTION_UP": 4,
    "PORTION_DOWN": 5,
    "PLAN_FOR_LISA": 6,
    "PLAN_FOR_JAKOB": 7,
    "UNPLAN": 8,
    "REPAIR": 9,
    "PLAN_BOTH": 10,
    "CHECK_OFF_LISTITEM": 11,
    "EDIT": 12,
    "DELETE_GOAL": 13,
    "DELETE_LIST": 14,
    "EDIT_GOAL": 14,
});

export function Button(props) {

    const [tooltip, setTooltip] = useState(null)
    return <button
        title={props.title}
        onClick={() => errorWrap(tooltip, props.callback)}
        className={"btn btn-circle btn-sm " + props.color}>
        <FontAwesomeIcon icon={props.icon}/>
        <p ref={ref => setTooltip(ref)} data-tip={"Unknown Error"}></p>
    </button>

}

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <button className={"btn btn-circle btn-sm btn-secondary"}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
    >
        {children}
    </button>
));

const ButtonDropdownMenu = ({items}) => {
    return (
        <Dropdown as={ButtonGroup} className={"mobile-content"}>
            <Dropdown.Toggle as={CustomToggle}>...</Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-buttons">
                {items.map((item, index) => (
                    <Dropdown.Item key={index} className="dropdown-button">
                        {item}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export function ButtonList(props) {
    const item = props.item
    const showTodoModal = props.showTodoModal
    const showGoalModal = props.showGoalModal
    const date = props.date
    let buttons = []

    const queryClient = useQueryClient()

    const deleteGoalMutation = useMutation({
        mutationFn: deleteGoal, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['projects']})
        }
    })

    const deleteEntityMutation = useMutation({
        mutationFn: deleteEntity, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    const deleteListMutation = useMutation({
        mutationFn: deleteList, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['lists']})
        }
    })
    const repairTodoMutation = useMutation({
        mutationFn: repairTodo, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    const checkOffTodoMutation = useMutation({
        mutationFn: checkOffTodo, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    const checkOffListItemMutation = useMutation({
        mutationFn: checkOffListItem, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['list']})
        }
    })

    const planTodoAtMutation = useMutation({
        mutationFn: planTodoAt, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    const unplanTodoMutation = useMutation({
        mutationFn: unplanTodo, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    const startTodoMutation = useMutation({
        mutationFn: startTodoNow, onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['todos']})
        }
    })

    props.options.forEach((button, index) => {
        switch (button) {
            case btnoptions.CHECK_OFF_TODO:
                buttons.push(<Button key={index} title="Check off todo"
                                     callback={() => checkOffTodoMutation.mutate(item)} icon={solid('check')}
                                     color={'btn-success'}/>)
                break
            case btnoptions.CHECK_OFF_LISTITEM:
                buttons.push(<Button key={index} title="Check off"
                                     callback={() => checkOffListItemMutation.mutate(item)} icon={solid('check')}
                                     color={'btn-success'}/>)
                break
            case btnoptions.PLAN_NOW:
                buttons.push(<Button key={index} title="Plan todo now"
                                     callback={() => {
                                         planTodoAtMutation.mutate({item, date})
                                     }}
                                     icon={solid('level-up-alt')} color={'btn-primary'}/>)
                break
            case btnoptions.START_NOW:
                if (!item.done) {
                    buttons.push(
                        <Button key={index} title="Set todo start time to current time"
                                callback={() => startTodoMutation.mutate(item)}
                                icon={solid('play')} color={'btn-success'}/>)
                }
                break
            case btnoptions.DELETE:
                buttons.push(
                    <Button key={index} title="Delete todo" callback={() => deleteEntityMutation.mutate(item)}
                            icon={deleteEntityMutation.isLoading ? solid('spinner') : solid('trash')}
                            color={'btn-danger'}/>)
                break
            case btnoptions.PORTION_UP:
                break
            case btnoptions.PORTION_DOWN:
                break
            case btnoptions.UNPLAN:
                buttons.push(
                    <Button key={index} title="Remove planned and start time from todo"
                            callback={() => unplanTodoMutation.mutate(item)}
                            icon={solid('level-down-alt')} color={'btn-primary'}/>)
                break
            case btnoptions.REPAIR:
                buttons.push(
                    <Button key={index} title="Repair todo" callback={() => repairTodoMutation.mutate(item)}
                            icon={repairTodoMutation.isLoading ? solid('spinner') : solid('wrench')}
                            color={'btn-info'}/>)
                break
            case btnoptions.EDIT:
                buttons.push(
                    <Button key={index} title="Edit todo" callback={() => props.onEdit ? props.onEdit(item) : showTodoModal(item)} icon={solid('wrench')}
                            color={'btn-warning'}/>)
                break
            case btnoptions.DELETE_GOAL:
                buttons.push(
                    <Button key={index} title="Delete goal" callback={() => deleteGoalMutation.mutate(item)}
                            icon={deleteGoalMutation.isLoading ? solid('spinner') : solid('trash')}
                            color={'btn-danger'}/>)
                break
            case btnoptions.EDIT_GOAL:
                buttons.push(
                    <Button key={index} title="Edit goal" callback={() => showGoalModal(item)} icon={solid('wrench')}
                            color={'btn-warning'}/>)
                break
            case btnoptions.DELETE_LIST:
                buttons.push(
                    <Button key={index} title="Delete list" callback={() => deleteListMutation.mutate(item)}
                            icon={solid('trash')} color={'btn-danger'}/>)
                break
            default:
                throw new Error("Unknown button")
        }
    })

    return <span><span className={"desktop-content"}>
        {buttons.map((button, index) => {
            return <span key={"buttondropdown-" + index} style={{marginLeft: ".5em"}}>{button}</span>
        })}</span>
        <ButtonDropdownMenu items={buttons}/></span>;

}
