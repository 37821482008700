import moment from 'moment'
import {getCurrentTime, getMorningTime, MORNING_TIME} from "../helpers/dateHelper";
import {durationToString} from "../helpers/helpers";

const parsers = [parseProject, parseTimeConstraints, parseStartTime, parseDuration, parseDone, parseRepetition, parsePriority, parseBounty, parseMulti, parseAssignment]

const reverseParsers = [unparseProject, unparseTimeConstraints, unparseStartTime, unparseDuration, unparseDone, unparsePriority, unparseBounty]

export function unparseTask(dict) {
    let parts = [dict["name"]]

    reverseParsers.forEach(reverseParser => {
        let text = reverseParser(dict)
        if (text) {
            parts.push(text)
        }
    })

    return parts.join(" ")
}

export function parseTask(words) {
    const taskDict = {}
    let name = []

    let parsed_total = 0
    let parsed
    words.forEach(word => {
        if (word === "") {
            return
        }

        parsed = 0
        parsers.forEach(parser => {
            if (parser(taskDict, word)) {
                parsed++
                parsed_total++
            }
        })

        if (parsed > 1) {
            throw new Error("Ambiguous option found. No task was submitted.")
        }
        if (parsed === 0) {
            if (parsed_total > 0) {
                throw new Error("Commands are only allowed at the end of the string. No task was submitted.")
            }
            name.push(word)
        }
    })

    taskDict.name = name.join(" ")

    return taskDict
}

export function parseAssignment(dict, text) {
    if (text.startsWith("@") && text.length > 1) {
        dict["assignee"] = text.substring(1)
        return true
    }
    return false;
}

export function parseDone(dict, text) {
    if (text === "~") {
        dict["done"] = true
        return true
    }

    return false
}

export function unparseDone(dict) {
    if ("done" in dict && dict["done"]) {
        return "~"
    }

    return false
}

const multiRegex = /^x(\d+)((?:s|mi|h|d|w|mo|y)?)(\d*)$/
const units = ["s", "mi", "h", "d", "mo", "y"]

export function parseMulti(dict, text) {
    let match = text.match(multiRegex);

    if (match == null) {
        return false
    }

    let times = match[1]
    let unit = match[2]
    let amount = match[3]

    if (amount === "") {
        amount = 1
    } else {
        amount = parseInt(amount)
    }

    if (unit === "") {
        unit = 6
    } else if (unit === "w") {
        unit = 3
        amount *= 7
    } else {
        unit = units.indexOf(unit)
    }

    dict["repetition"] = {}
    dict["repetition"]["type"] = unit
    dict["repetition"]["interval"] = amount
    dict["repetition"]["times"] = times

    return true
}

export function parsePriority(dict, text) {
    if (text === "!") {
        dict["priority"] = 1
        return true
    } else if (text === "!!") {
        dict["priority"] = 2
        return true
    } else if (text === "!!!") {
        dict["priority"] = 3
        return true
    } else if (text === "?") {
        dict["priority"] = -1
        return true
    } else if (text === "??") {
        dict["priority"] = -2
        return true
    } else if (text === "???") {
        dict["priority"] = -3
        return true
    }

    if (text.startsWith("!")) {
        const prio = parseInt(text.substr(1))
        if (!isNaN(prio)) {
            dict["priority"] = prio
            return true
        }
    }

    return false
}

export function unparsePriority(dict) {
    if (!("priority" in dict) || dict["priority"] === 0) {
        return false;
    }

    switch (dict["priority"]) {
        case 1:
            return "!"
        case 2:
            return "!!"
        case 3:
            return "!!!"
        case -1:
            return "?"
        case -2:
            return "??"
        case -3:
            return "???"
        default:
            throw Error(dict["priority"])
    }
}

export function parseProject(dict, text) {
    if (!text.startsWith("#")) {
        return false
    }

    dict.project = text.substr(1)
    return true
}

export function unparseProject(dict) {
    if (!("project" in dict)) {
        return false
    }

    return "#" + dict["project"]
}

export function parseTimeConstraints(dict, text) {
    let times = text.split(">")
    if (times.length !== 2) {
        return false
    }

    if (times[0] !== "") {
        let time = parseTime(times[0])
        if (time) {
            dict.planned_after = time.unix();
        } else {
            return false
        }
    }
    if (times[1] !== "") {
        let time = parseTime(times[1])
        if (time) {
            dict.due = time.unix();
        } else {
            return false
        }
    }
    if (times[0] === "" && times[1] === "") {
        dict.planned_after = getMorningTime().unix()
    }
    return true
}

export function unparseTimeConstraints(dict) {
    if (!("planned_after" in dict) && !("due" in dict)) {
        return false
    }

    let text = ""

    if ("planned_after" in dict) {
        text += unparseTime(dict.planned_after)
    }

    text += ">"

    if ("due" in dict) {
        text += unparseTime(dict.due)
    }

    return text
}

export function parseStartTime(dict, text) {
    const time = parseTime(text, false)
    if (time) {
        dict.start_time = time.unix();
        return true
    }
    return false
}

export function unparseStartTime(dict) {
    if (!("start_time" in dict)) {
        return false
    }

    return unparseTime(dict.start_time)
}

export function parseDuration(dict, text) {
    let duration = 0

    if (text.includes("h")) {
        let split = text.split("h")
        if (split.length !== 2) {
            return false
        }
        let hours = parseInt(split[0])
        if (isNaN(hours)) {
            return false
        }
        duration += hours * 60 * 60
        text = split[1]

    }

    if (text.includes("m")) {
        let split = text.split("m")
        if (split.length !== 2) {
            return false
        }
        let minutes = parseInt(split[0]);
        if (isNaN(minutes)) return false
        duration += minutes * 60
        text = split[1]
    }

    // if (text.includes("s")) {
    //     let split = text.split("s")
    //     if (split.length !== 2) {
    //         return false
    //     }
    //     let seconds = parseInt(split[0]);
    //     if (isNaN(seconds))
    //         return false
    //     duration += seconds
    //     text = split[1]
    // }

    if (text === "" && !(duration === 0)) {
        dict["duration"] = duration
        return true
    } else {
        return false
    }
}

export function unparseDuration(dict) {
    if ("duration" in dict) {
        return durationToString(dict["duration"])
    }
    return false
}

export function parseRepetition(dict, text) {
    var matches = /^&(\d+)([dmwy])$/g.exec(text)

    if (matches === null) {
        return false;
    }

    var interval = matches[1]
    var type = matches[2]

    if (type === "d") {
        type = 0;
    } else if (type === "w") {
        type = 0
        interval *= 7
    } else if (type === "m") {
        type = 1
    } else if (type === "y") {
        type = 2;
    } else {
        throw Error(type)
    }

    dict["repetition"] = {
        "interval": interval, "type": type
    }

    return true
}

export function parseTime(text, allowShortDays = true) {
    let dates = [
        moment(text, "D.M.YYYY", true),
        moment(text, "D.M.YY", true),
        moment(text, "D.MM.YY", true),
        moment(text, "DD.M.YY", true),
        moment(text, "DD.MM.YY", true),
        moment(text, "D.M.", true),
        moment(text, "DD.M.", true),
        moment(text, "D.MM.", true),
        moment(text, "DD.MM.", true),
        moment(text, "dddd", true)]
    if (allowShortDays) {
        dates.push(moment(text, "dd", true))
    }

    let times = [
        moment(text, "YYYY-MM-DDTHH:mm", true),
        moment(text, "D.M.YYYY_H:mm", true),
        moment(text, "D.M.YY_H:mm", true),
        moment(text, "D.M._H:mm", true),
        moment(text, "D.M_H:mm", true),
        moment(text, "dddd_H:mm", true),
        moment(text, "dd_H:mm", true),
        moment(text, "H:mm", true),]

    for (let time of times) {
        if (time.isValid()) {
            return time
        }
    }

    for (let time of dates) {
        if (time.isValid()) {
            time = time.set(MORNING_TIME)
            return time
        }
    }

    return false
}

export function unparseTime(time) {
    let parsed = moment(time["$date"]);
    const current = getCurrentTime()
    if (parsed.year() !== current.year()) {
        return parsed.format("D.M.YYYY_H:mm")
    } else if (parsed.month() !== current.month() || parsed.date() !== current.date()) {
        return parsed.format("D.M._H:mm")
    } else {
        return parsed.format("H:mm")
    }
}

export function parseBounty(dict, text) {
    if (text.endsWith("$")) {
        let split = text.split("$")
        if (split.length !== 2) {
            return false
        }
        let bounty = parseInt(split[0])
        if (isNaN(bounty)) {
            return false
        }
        dict["bounty"] = bounty
        return true
    }
    return false
}

export function unparseBounty(dict) {
    if ("bounty" in dict) {
        return dict["bounty"] + "$"
    }
    return false
}