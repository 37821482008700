import React, {useRef, useState} from 'react';
import styled from "styled-components";
import ReactTooltip from 'react-tooltip'
import {priorityToColor, stringToColor} from "../helpers/helpers";
import {parseTask, unparseTask} from "../parser/TaskParser";
import {updateItem} from "../api/entityApiHandler";
import {ButtonList} from "./ui/ButtonList";
import {useMutation, useQueryClient} from "react-query";

const Text = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${({invalid}) => invalid ? "color: red;" : ""}
`

const TodoWrapper = styled.span`
    padding: .5rem 1rem;
    border-radius: .5em;
    border-width: 1px;
    border-style: solid;
    border-color: #e3e6f0;
    display: flex;
    justify-content: space-between;
    ${({item}) => "project" in item ? "border-left-width: 0.25rem; border-left-style: solid; border-left-color: " + stringToColor(item.project) + ";" : ""}
    ${({item}) => ("priority" in item && item["priority"] !== 0) ? "border-right-width: 0.25rem; border-right-style: solid; border-right-color: " + priorityToColor(item.priority) + ";" : ""}
`

function TodoEntry(props) {
    const [editMode, setEditMode] = useState(false)
    const [hiddenDict, setHiddenDict] = useState({})
    const [text, setText] = useState("")
    const [errorMsg, setErrorMsg] = useState("No error")
    const tooltip = useRef()
    const queryClient = useQueryClient()
    const mutateItem = useMutation({
        mutationFn: updateItem,
        onSuccess: () => {
            // TODO Performance Resource intensive
            queryClient.invalidateQueries([])
        }
    })

    const handleClick = (e) => {
        if (e.detail === 2) {
            setEditMode(true)
            setHiddenDict(props.hiddenProps ? props.hiddenProps(props.item) : {})
            setText(unparseTask(props.item))
        }
    };

    const handleKey = event => {
        if (event.keyCode === 27) {
            event.preventDefault();
            setEditMode(false)
            setText("")
        }
    }

    const updateText = (e) => {
        setText(e.target.value)
    }

    const submit = (e, item) => {
        try {
            e.preventDefault()
            if (text !== "") {
                const words = text.split(' ')
                let modifiedTask = parseTask(words);
                for (const key in item) {
                    if (!(key in modifiedTask)) {
                        modifiedTask[key] = null
                    }
                }
                modifiedTask["_id"] = item["_id"]
                modifiedTask["owner"] = item["owner"]
                modifiedTask["type"] = item["type"]

                modifiedTask = Object.assign({}, modifiedTask, hiddenDict);

                mutateItem.mutate(modifiedTask)
                setText("")
                setEditMode(false)
            } else {
                throw new Error("Cannot submit empty todo")
            }
        } catch (e) {
            tooltip.current.setAttribute("data-tip", e.toString())
            tooltip.current.setAttribute("data-type", "error")
            ReactTooltip.show(tooltip.current)
        }
    }


    const item = props.item
    const date = props.currentDate
    if (editMode) {
        return <TodoWrapper item={item} done={item.done}>
            <form onSubmit={(e) => submit(e, item)}
                  className={"mr-auto w-100 navbar-search"}>
                <ReactTooltip type={"error"}/>
                <p ref={ref => tooltip.current = ref} data-tip={"Unknown Error"}></p>
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small"
                           placeholder={props.placeholder}
                           onChange={updateText}
                           value={text}
                           onKeyDown={handleKey}
                           autoFocus
                    />
                </div>
            </form>
        </TodoWrapper>
    } else {
        let buttons = <ButtonList item={item} date={date} options={props.options}
                                  showTodoModal={props.showTodoModal}/>;

        let text = props.getText(item);

        return (<TodoWrapper data-tip={item.description} item={item} className={"text-dark"}>
            <ReactTooltip type={"error"}/>
            <Text invalid={props.invalid} onClick={handleClick}>
                {text}
            </Text>
            <span style={{
                whiteSpace: "nowrap",
            }}>
                    {buttons}
                </span>
        </TodoWrapper>);
    }
}

export default TodoEntry;