import {Calendar, momentLocalizer,} from 'react-big-calendar'
import moment from 'moment'
import React, {useMemo} from "react";
import {stringToColor} from "../../../helpers/helpers";
import {getCheckins} from "../../../api/todoApiHandler";
import {useQuery} from "react-query";

const localizer = momentLocalizer(moment)

const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'green',
        },
    })

export function GoalHistory() {
    const checkinDataQuery = useQuery('checkins', getCheckins)
    const { components, defaultDate, max, views } = useMemo(
        () => ({
            components: {
                timeSlotWrapper: ColoredDateCellWrapper,
            },
            views: {
                month: true,
            },
        }),
        []
    )

    let events = []
    let i = 0
    if (checkinDataQuery.isSuccess) {
        for (let checkin of checkinDataQuery.data) {
            for (let goal of checkin.goal_progress) {
                if (goal.done) {
                    events.push({
                        id: i++,
                        title: goal.name,
                        start: new Date(checkin.date),
                        end: new Date(checkin.date),
                    })
                }
            }
        }
    }

    return     <div>
        <Calendar
            components={components}
            defaultDate={defaultDate}
            events={events}
            localizer={localizer}
            max={max}
            showMultiDayTimes
            step={60}
            eventPropGetter={(event, start, end, isSelected) => ({
                style: {
                    backgroundColor: stringToColor(event.title),
                },
            })
            }
            views={views}
            style={{ height: 800 }}
        />
    </div>
}