import {authHeader} from "./authHeader"
import {handleResponse} from "./handleResponse"
import {updateItem} from "./entityApiHandler"

export function submitListEntry({listid, entry}) {
    const requestOptions = {
        method: 'POST', headers: authHeader({'Content-Type': 'application/json'}), body: JSON.stringify({
            name: entry
        }),
    };

    return fetch(process.env.REACT_APP_API_URL + '/list/' + listid, requestOptions)
        .then(handleResponse)
}

export function checkOffListItem(item) {
    item.done = !item.done;

    return updateItem(item)
}

export function deleteList(listid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    return fetch(process.env.REACT_APP_API_URL + '/wishlists?_id=' + listid, requestOptions)
        .then(handleResponse);
}