import {addUpRecipeStats} from "../components/modals/AddRecipeModal";
import {addUpDicts, calculateStatsFromAmount} from "./helpers";

export function calculateItemStats(item, ingredients, recipes) {
    if (item["food_type"] === "recipe") {
        if (recipes[item["name"]]) {
            item["stats"] = addUpRecipeStats(item["amount"], recipes[item["name"]]["ingredients"], ingredients)
            return item["stats"]
        } else {
            console.error("Recipe " + item["name"] + " not found!")
        }
    } else if (item["food_type"] === "ingredient") {
        if (ingredients[item["name"]]) {
            item["stats"] =  calculateStatsFromAmount(item["amount"], item["unit"], ingredients[item["name"]])
            return item["stats"]
        } else {
            // TODO errors
            console.error("Ingredient " + item["name"] + " not found!")
        }
    } else {
        console.error("Unknown or missing type " + item["food_type"])
    }

    return {};
}

export function calculateStats(plans, ingredients, recipes) {
    let totalStats = {"energy-kcal": 0, "carbohydrates": 0, "fat": 0, "proteins": 0}
    let errors = ""
    for (let plan of plans) {
        if (plan) {
            for (let item of plan) {
                let itemStats = calculateItemStats(item, ingredients, recipes)
                if (!itemStats || !("energy-kcal" in itemStats) || !("carbohydrates" in itemStats) || !("fat" in itemStats) || !("proteins" in itemStats)) {
                    let missing = [];
                    if (!itemStats) {
                        missing.push("stats");
                    } else {
                        if (!itemStats["energy-kcal"]) {
                            missing.push("energy-kcal");
                        }
                        if (!itemStats["carbohydrates"]) {
                            missing.push("carbohydrates");
                        }
                        if (!itemStats["fat"]) {
                            missing.push("fat");
                        }
                        if (!itemStats["proteins"]) {
                            missing.push("proteins");
                        }
                    }

                    errors += "\"" + item["name"] + "\" is missing the following information: " + missing.join(", ") + "! It was not included in the total.\n";
                } else {
                    totalStats = addUpDicts(totalStats, itemStats);
                }
            }
        }
    }
    return {totalStats, errors};
}