import React from 'react';
import {Link} from "react-router-dom";

function Sidebar(props) {
    return (
        <ul className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" + (props.toggled ? " toggled" : "")}
            id="accordionSidebar">

            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={"/app/dashboard"}>
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-plane-departure"/>
                </div>
                <div className="sidebar-brand-text mx-3">takeControl Web</div>
            </Link>

            <hr className="sidebar-divider my-0"/>

            <li className="nav-item">
                <Link className="nav-link" to="/app/dashboard">
                    <i className="fas fa-fw fa-tachometer-alt"/>
                    <span>Dashboard</span></Link>
            </li>

            {/*<li className="nav-item">*/}
            {/*    <Link className="nav-link" to={"/calendar"}>*/}
            {/*        <i className="fas fa-fw fa-calendar"/>*/}
            {/*        <span>Calendar</span></Link>*/}
            {/*</li>*/}

            <hr className="sidebar-divider"/>

            <div className="sidebar-heading">
                Tasks
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="/app/planner">
                    <i className="fas fa-fw fa-pencil-ruler"/>
                    <span>Planner</span>
                </Link>
            </li>

            {/*<li className="nav-item">*/}
            {/*    <Link className="nav-link" to="/app/money">*/}
            {/*        <i className="fas fa-fw fa-coins"/>*/}
            {/*        <span>Money</span>*/}
            {/*    </Link>*/}
            {/*</li>*/}

            {/*<li className="nav-item">*/}
            {/*    <Link className="nav-link" to="/app/creditsshop">*/}
            {/*        <FontAwesomeIcon icon={solid('coins')}/>*/}
            {/*        <span>Shop</span>*/}
            {/*    </Link>*/}
            {/*</li>*/}

            <li className="nav-item">
                <Link className="nav-link" to="/app/goals">
                    <i className="fas fa-fw fa-tasks"/>
                    <span>Goals</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/app/statistics">
                    <i className="fas fa-fw fa-chart-line"/>
                    <span>Statistics</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/app/goalhistory">
                    <i className="fas fa-fw fa-chart-area"/>
                    <span>Goal History</span>
                </Link>
            </li>

            <hr className="sidebar-divider"/>

            <div className="sidebar-heading">
                Food
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="/app/foodplanner">
                    <i className="fas fa-fw fa-apple-alt"/>
                    <span>Food Planner</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/app/scanner">
                    <i className="fas fa-fw fa-barcode"/>
                    <span>Food Scanner</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/app/fooddata">
                    <i className="fas fa-fw fa-apple-alt"/>
                    <span>Food Data</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/app/shoplist">
                    <i className="fas fa-fw fa-scroll"/>
                    <span>Shopping List</span>
                </Link>
            </li>

            {/*<li className="nav-item">*/}
            {/*    <Link className="nav-link" to="/app/food">*/}
            {/*        <i className="fas fa-fw fa-apple-alt"/>*/}
            {/*        <span>Food (deprecated)</span>*/}
            {/*    </Link>*/}
            {/*</li>*/}

            {/*<hr className="sidebar-divider"/>*/}

            {/*<div className="sidebar-heading">*/}
            {/*    Money*/}
            {/*</div>*/}

            {/*<li className="nav-item">*/}
            {/*    <Link className="nav-link" to="/">*/}
            {/*        <i className="fas fa-fw fa-table"/>*/}
            {/*        <span>Overview</span></Link>*/}
            {/*</li>*/}

            {/*<li className="nav-item">*/}
            {/*    <Link className="nav-link" to="/">*/}
            {/*        <i className="fas fa-fw fa-chart-area"/>*/}
            {/*        <span>Balance</span></Link>*/}
            {/*</li>*/}

            <hr className="sidebar-divider"/>

            <div className="sidebar-heading">
                SOCIAL
            </div>

            <li className="nav-item">
                <Link className="nav-link" to="/app/wishlists">
                    <i className="fas fa-fw fa-pencil-ruler"/>
                    <span>Wish Lists</span>
                </Link>
            </li>


            <hr className="sidebar-divider d-none d-md-block"/>

            <div className="sidebar-heading">
                Help
            </div>


            <li className="nav-item">
                <Link className="nav-link" to="/app/commands">
                    <i className="fas fa-fw fa-question"/>
                    <span>Commands</span></Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link" to="/app/problems">
                    <i className="fas fa-fw fa-wrench"/>
                    <span>Problems</span></Link>
            </li>

            <hr className="sidebar-divider d-none d-md-block"/>

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"
                        onClick={props.onSidebarToggle}/>
            </div>
        </ul>
    );
}

export default Sidebar;
